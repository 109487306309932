.container {
  margin-top: 24px;
}

.card {
  display: grid;
  grid-template-columns: 32px 1fr 75px 50px;
  grid-column-gap: 16px;
  background: var(--white-color);
  padding: 0 12px;
  border-radius: 4px;
  height: 55px;
  align-items: center;

  &.error {
    border: 1px solid var(--secondary-color);
  }

  &Icon {
    max-width: 32px;
    max-height: 32px;
    width: 100%;
    display: block;
  }

  > input {
    border: none;

    &:focus-visible {
      outline: none;
    }
  }
}

.errorMsg {
  margin-top: 10px;
  color: var(--secondary-color);
}

.strong {
  font-weight: 600;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 32px;
  gap: 15px;

  .button {
    color: var(--white-color);
    background-color: var(--primary-color);
    cursor: pointer;

    > span {
      color: var(--white-color);
    }
  }
}

.balance {
  width: 100%;
  text-align: center;
  padding: 10px;
  color: var(--white-color);
  margin-top: 15px;
  background: var(--provider-payment-color-extra-shift-day);

  &.empty {
    background: var(--shift-bg-color-negative);
    color: var(--primary-color);
  }
}

.added {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 20px 0;
  padding: 15px 60px;
  background: #48d2a026;

  > svg {
    color: var(--success-color);
    font-size: 30px;
  }
}
