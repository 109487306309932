.container {
  background: white;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 6.5rem;
  position: relative;

  .confetti {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0.5;
    background: url('/assets/celebrationBackground.svg');

    @media print {
      background-size: 4.168125rem 2.77875rem;
    }
  }

  .confettiIcon {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  }

  .value {
    padding-top: 1.25rem;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.4375rem;
    letter-spacing: 0;
  }

  .label {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.13125;
    letter-spacing: 0;
    margin-top: 0.8rem;
    text-align: center;
  }

  .subLabel {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.13125;
    letter-spacing: 0;
    color: #10365acc;
  }
}
