.shiftInfo {
  position: absolute;
  right: -7px;
  z-index: 10000;
  background: white;
  box-shadow: 0 1px 4px #00000080;
  text-align: center;
  color: var(--staff-color-text);
  padding: 10px 10px 5px 10px;
  cursor: default;

  .message {
    width: 100%;
    min-width: 150px;
    text-align: left;
  }

  .name {
    white-space: nowrap;
  }

  .hint {
    width: 100%;
    font-size: var(--font-size-extra-small);
    white-space: nowrap;
  }

  .buttons {
    width: 100%;
    text-align: right;
  }

  .arrowBottom {
    position: absolute;
    right: 10px;
    width: 0;
    height: 0;
    bottom: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--white-color);
  }

  .arrowTop {
    position: absolute;
    right: 10px;
    width: 0;
    height: 0;
    top: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--white-color);
  }

  .underStaffed {
    white-space: nowrap;
    background-color: transparent;
    color: var(--staff-color-understaffed);
  }

  .overStaffed {
    white-space: nowrap;
    background-color: transparent;
    color: var(--staff-color-overstaffed);
  }

  button {
    background-color: var(--tooltip-button-bg-color);
    color: var(--primary-color);
    border: none;
    font-size: var(--font-size-medium);
    margin-top: 5px;
    padding: 5px 10px;
    cursor: pointer;

    &:focus,
    &:active {
      outline: 0;
    }
  }
}

.top {
  bottom: 26px;
}

.bottom {
  top: 26px;
}
