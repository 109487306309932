.banner {
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 #00000033;

  &.blue {
    background-color: var(--provider-payment-color-extra-shift-day);
    color: var(--white-color);
  }

  &.white {
    background-color: var(--tooltip-button-bg-color);
    color: var(--primary-color);
  }

  &.orange {
    background-color: var(--shift-bg-color-negative);
    color: var(--error-color-alt);
  }

  .message {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    font-weight: 500;
    white-space: pre-line;

    &.optInMessage {
      flex-direction: column;
      gap: 0;
    }
  }

  .sendTextButton {
    background-color: var(--secondary-color);
    color: var(--white-color);
    border-radius: 20px;
    padding: 8px 15px;

    &:hover {
      background-color: var(--secondary-color);
      color: var(--white-color);
      opacity: 0.8;
      cursor: pointer;
    }
  }

  .optInButton {
    background-color: var(--primary-color);
    color: var(--white-color);
    border-radius: 20px;
    padding: 8px 15px;

    &:hover {
      background-color: var(--primary-color);
      color: var(--white-color);
      opacity: 0.8;
      cursor: pointer;
    }
  }
}

.notOptedInDivider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  margin: 20px 10px;

  &:before,
  &:after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: var(--error-color-alt);
  }

  span {
    border-radius: 20px;
    color: var(--white-color);
    background-color: var(--error-color-alt);
    padding: 8px 14px 5px;
  }
}
