.root {
  background-color: var(--white-color);
  border-radius: 4px;

  @media (hover: hover) {
    cursor: pointer;
  }

  &:hover,
  &.selected {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.29);
  }

  .icon {
    color: var(--secondary-color);
  }
}

.right {
  text-align: right;
}
