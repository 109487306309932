.textLine {
  padding: 10px 0 10px 0;
  font-size: var(--font-size-medium);
  display: flex;
  margin: 10px 15px 0 15px;
}

.highlighted {
  background-color: rgba(247, 181, 0, 0.15);
}

.bullet {
  width: 0.4em;
  height: 0.4em;
  border-radius: 50%;
  display: inline-block;
  margin: 0.3em 0.5em 0.3em 0.3em;
  float: left;
}

.textLeft {
  flex-grow: 1;
  margin-right: 1em;
}

.eventDatetime {
  color: var(--light-info-color);
  margin-right: 0.5em;
}

.bgCreated {
  background-color: transparent;
}

.bgCancelled {
  background-color: var(--appointment-log-filter-color-cancels);
}

.bgRescheduled {
  background-color: var(--appointment-log-filter-color-reschedules);
}

.bgNoShow {
  background-color: var(--appointment-log-filter-color-no-shows);
}
