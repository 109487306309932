@use 'src/styles/flyout';
@import 'src/styles/variables';

.hint {
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  padding: 20px 0;

  p {
    margin: 0;
  }
}
