.container {
  position: relative;
  width: calc(100% - (var(--flyout-padding) * 2));
  margin-left: var(--flyout-padding);
  margin-right: var(--flyout-padding);

  --border-radius: 3px;
  --due-date-color-column-width: 4px;

  border: none;
  border-radius: var(--border-radius);

  &:hover {
    box-shadow: 0 1px 6px #00000080;
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
  }
}

.dueDateColorColumn {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: var(--due-date-color-column-width);
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.content {
  position: relative;
  left: var(--due-date-color-column-width);
  top: 0;
  width: calc(100% - var(--due-date-color-column-width));
  background: var(--white-color);
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.taskCategoryIcon {
  position: relative;
  top: -4px;
  height: 12px;
  width: 12px;
  fill: var(--light-info-color);
  margin-right: 5px;
}

.icon {
  position: relative;
  top: -1px;
  margin-right: 2px;
}

.header {
  position: relative;
  width: 100%;
  height: 35px;
}

.headerLeft {
  position: absolute;
  top: 15px;
  left: 15px;
}

.headerRight {
  position: absolute;
  top: 15px;
  right: 15px;
}

.textBlock {
  margin: 8px 36px 0 18px;
  background-color: var(--text-block-color);
  padding: 14px;
}

.text {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-medium);
}

.smallText {
  color: var(--light-info-color);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
}

.footer {
  position: relative;
  margin: 15px 15px 15px 10px;
  padding-bottom: 5px;
}

.footerItem {
  display: inline-flex;
  align-items: flex-end;
}

.footerText {
  display: inline-block;
  padding-right: 8px;
}
