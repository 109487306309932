@use 'src/styles/flyout';

.root {
  padding: 0 var(--flyout-padding) var(--flyout-padding);
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;

  &,
  * {
    box-sizing: border-box;
  }
}

.addClientContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  position: relative;

  & > span {
    display: flex;
    flex-direction: column;
    align-items: left;
    position: relative;
    justify-content: space-evenly;
  }
}
