.container {
  background: white;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1rem 0.75rem 1rem;
  height: 9rem;

  .label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.0625rem;
    letter-spacing: 0;
  }

  .chartContainer {
    flex: 1;
    width: 100%;
    padding-top: 0.5rem;
    margin-bottom: -1rem;

    .topValue {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.125rem;
      letter-spacing: 0;
      text-align: center;
    }
  }

  .xAxisLabelWrapper {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      .xAxisLabel {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 0.9375rem;
        letter-spacing: 0;
        text-align: center;

        line-break: anywhere;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.responsiveChart {
  max-width: 100%;
  max-height: 100%;
}
