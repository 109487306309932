@use 'src/styles/flyout';

.container {
  display: grid;
  grid-template-rows: repeat(5, max-content) 1fr;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
}

.flyoutBlock.first {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .flyoutTitle {
    flex-grow: 1;
  }

  .bulkSignButton {
    background-color: var(--white-color);
    color: var(--active-color);
    border: 1px solid var(--active-color);
    padding: 10px 15px 0;
  }

  .loadAllButton {
    background-color: var(--active-color);
    color: var(--white-color);
    padding: 10px 15px 0;
  }
}

.encounterList {
  height: 100%;
  overflow-y: scroll;
  margin-top: var(--flyout-padding);
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}
