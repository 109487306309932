.container {
  flex: 1;
  background: white;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1rem 0.5rem 1rem;

  .label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.0625rem;
    letter-spacing: 0;
  }

  .subLabel {
    padding-top: 0.25rem;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.0625rem;
    letter-spacing: 0;
  }

  .chartContainer {
    flex: 1;
    width: 100%;

    .topValue {
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1.125rem;
      letter-spacing: 0;
      text-align: center;
    }
  }

  .xAxisLabelWrapper {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      .xAxisLabel {
        font-size: 0.6875rem;
        font-weight: 400;
        line-height: 0.9375rem;
        letter-spacing: 0;
        word-break: break-word;
        line-break: strict;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.legend {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -0.3125rem;

  .legendItem {
    margin: 0.3rem;
    color: #10365a;
    display: flex;
    align-items: center;
    gap: 0.2rem;

    font-size: 0.625rem;
    font-weight: 400;
  }

  .legendItemIcon {
    width: 0.8rem;
    height: 0.8rem;
    display: flex;
    align-items: center;
    overflow: hidden;

    .dashedLine {
      border-top: 2px solid black;
      border-top-style: dashed;
      flex: 1;
      margin-left: -2px;
      margin-right: -2px;
    }
  }
}
