.paper {
  margin: 2.5rem 0;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 2.25rem;
  background-color: var(--white-color);
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(16, 54, 90, 0.2);

  .confetti {
    position: absolute;
    top: -1rem;
    right: 0;
    width: 5rem;
    height: 5rem;
  }

  .dog {
    width: 6rem;
    height: 6rem;
    opacity: 0.7;
  }

  .priceContainer {
    .price {
      color: var(--primary-color);
      font-size: 2.5rem;
      font-weight: 600;
      margin-bottom: 0.4rem;

      &.grayedOut {
        color: var(--light-info-color);
      }
    }

    .priceHighlighted {
      color: #10365a;
    }

    .info {
      margin-top: 0.4rem;
      margin-left: 0.2rem;
      color: rgba(16, 54, 90, 0.8);
      font-size: 1.2em;
    }
  }

  & > .title {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    color: rgba(var(--primary-color), 0.8);
  }

  & > .subtitle {
    width: 100%;
    text-align: center;
    font-weight: 300;
    font-size: 0.875rem;
    color: rgba(var(--primary-color), 0.8);
    margin-bottom: 20px;
    margin-top: 4px;
  }

  .collectedContainer {
    background: #f7f1eb;
    width: 90%;
    border-radius: 4px;
    display: flex;
    margin-top: 1.2rem;
    justify-content: space-between;
    align-items: center;
    padding: 0.6rem 1.5rem;
    color: #10365a;
    font-weight: 500;

    .price {
      font-weight: 600;
      font-size: 1.143rem;
    }
  }
}

.tabContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-left: 5%;
}

.progressContainer {
  width: 100%;
  margin-bottom: 2.5rem;

  .progressLabel {
    margin-top: 5rem;
    font-weight: 500;

    &.visitsLabel {
      margin-top: 2.5rem;
    }
  }
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}

.tooltip {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  padding: 1rem;
  box-shadow: 0 4px 8px 0 #33333326;
  width: 14rem;
  margin-left: 16rem;
  font-weight: 500;
  text-align: center;
  font-size: 0.8em;
  color: #10365a;
}
