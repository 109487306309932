h2.title {
  color: var(--primary-color);
  text-align: center;
  font-size: 24px;

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: var(--primary-color);
  }
}

.content {
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.section {
  background-color: #ebeff3;
  border-radius: 8px;
  padding: 15px;
}

.giftCardsTitle {
  display: flex;
  align-items: center;
  gap: 5px;

  > svg {
    width: 20px;
    height: 20px;
  }

  > h4 {
    margin: 3px 0 0;
  }
}

.giftCardWrapper {
  border-radius: 4px;
  border: 1px solid transparent;
  margin: 10px 0;
  padding: 10px;
  background-color: #ffffff66;

  &.selected {
    background-color: var(--white-color);
  }
  &.selected:not(.errorBorder) {
    border: 1px solid var(--primary-color);
  }

  .giftCard {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    .balanceWrapper {
      flex: 1;

      .balance {
        background: #dfebf5;
        padding: 7px 5px 5px;
        border-radius: 4px;
      }
    }

    div.input {
      border-radius: 4px;

      input {
        padding: 5px 10px 5px 0;
        width: 90px;
      }

      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .error {
    margin: 5px 0 0 10px;
  }
}

.errorBorder {
  border: 1px solid var(--error-color);
}

.error {
  color: var(--error-color);
}

.defaultCardTitle {
  align-items: center;
  display: flex;
  gap: 5px;

  > svg {
    margin-bottom: 4px;
  }
}

.defaultCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white-color);
  padding-right: 20px;

  .balance {
    font-weight: 600;

    &.empty {
      color: var(--status-color);
    }
  }
}

.chargeCardDisclaimer {
  background-color: var(--shift-bg-color-not-responded, #fffbf2);
  padding: 15px;
  margin-top: 0;
}

.bold {
  font-weight: 600;
}

button.submit {
  height: 34px;
  text-transform: none;
  display: flex;
  gap: 5px;

  .loading > svg {
    color: var(--white-color);
  }

  > svg {
    height: 24px;

    > path {
      fill: var(--white-color);
    }
  }
}

.success {
  background-color: #daf6ec;
  margin-top: 50px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  gap: 5px;
}

.close {
  align-self: flex-end;
}

.loadingDialog {
  width: 400px;
  height: 120px;
  align-items: center;
  justify-content: center;
}
