.container {
  display: grid;

  margin-top: calc(var(--flyout-padding) / 2);
}

.columns {
  grid-template-columns: repeat(2, 1fr) 1.5fr repeat(2, 1fr);
}

.item {
  background-color: unset;
  border: none;
  border-bottom: solid 3px #dce3ea;
  height: 45px;
  color: var(--primary-color);

  &.active {
    border-bottom: solid 3px var(--primary-color);
  }
}

.item:focus,
.item:active {
  outline: none;
}

.bullet {
  display: inline-block;
  top: -2px;
  left: -4px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  position: relative;
}

.badge {
  display: inline-block;
  position: relative;
  top: -6px;
  left: 1px;
  min-width: 14px;
  padding: 3px 3px;
  border-radius: 9px;
  font-size: 10px;
  color: var(--white-color);
  opacity: 0.8;
}

.bg-all {
  background-color: var(--appointment-log-filter-color-all);
}

.bg-cancelled {
  background-color: var(--appointment-log-filter-color-cancels);
}

.bg-rescheduled {
  background-color: var(--appointment-log-filter-color-reschedules);
}

.bg-noShow {
  background-color: var(--appointment-log-filter-color-no-shows);
}

.bg-blockoff {
  background-color: var(--appointment-log-filter-color-blockoff);
}
