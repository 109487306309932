$paddingTop: 10px;

.container {
  position: absolute;
  height: calc(100% - #{$paddingTop});
  background-color: var(--primary-color);
  width: var(--vs-ext-navigation-bar-size);
  float: right;
  right: 0;
  padding-top: $paddingTop;

  &.expanded {
    width: 100%;

    .expandButton .icon {
      margin-left: 1.5rem;
    }
  }

  .extensionVersion {
    font-size: 8px;
    position: absolute;
    bottom: 2px;
    right: 2px;
    color: darkgray;
    opacity: 0.5;
  }
}

.logo {
  width: 20px;
}

svg.icon {
  width: var(--navigation-button-icon-size);
  height: var(--navigation-button-icon-size);
  cursor: pointer;

  * {
    cursor: pointer;
  }
}

svg.sukiIcon {
  width: 40px;
  height: 45px;
  cursor: pointer;

  * {
    cursor: pointer;
  }
}

svg.icon path {
  fill: var(--navigation-button-color);
}

svg.reversedIcon {
  transform: rotate(180deg);
}

.expandButton {
  height: 2rem;

  background: none;
  border: none;

  position: absolute;
  bottom: 1rem;

  .icon {
    margin-left: 0.7rem;
    height: var(--navigation-button-icon-size);
    float: left;
  }
}

.editButton {
  height: 2rem;

  background: none;
  border: none;

  position: absolute;
  bottom: 1rem;
  right: 0.7rem;

  .icon {
    height: var(--navigation-button-icon-size);
    float: left;
  }
}

.buttonGroup {
  position: absolute;
  bottom: 1.1rem;
  height: 3rem;
  right: 0.7rem;

  button {
    height: 100%;
    border: none;
    border-radius: 50px;
    width: 7rem;
    font-size: 1.05rem;

    &.save {
      color: white;
      background: #ff4d4d;
    }

    &.cancel {
      color: rgba(255, 255, 255, 0.5);
      background: none;
    }
  }
}

.menuItemsContainer {
  max-height: 100%;
  margin-bottom: 4.5rem;

  display: grid;
  grid-template-rows: 50px auto 60px 1fr;

  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: auto;
}

.menuItemsContainer::-webkit-scrollbar {
  width: 5px;
}

.menuItemsContainer::-webkit-scrollbar:horizontal {
}

.menuItemsContainer::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  background-color: #8c97a6;
}

.menuItemsContainer::-webkit-scrollbar-track {
}

.placeholder {
  height: 5rem;
  background: rgba(211, 211, 211, 0.38);
  border-radius: 10px;
  margin-right: 2rem;
}

.emojiIcon {
  font-size: 26px;
  opacity: 1;
}
