.root {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  &.loading {
    background: #f7f9fc;
    justify-content: center;
    align-items: center;
  }
}
