@use 'src/styles/flyout';

.container {
  .title,
  .content {
    color: var(--primary-color);
  }

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: var(--primary-color);
  }

  .note {
    margin-bottom: 30px;
  }

  .providerSelect {
    width: 460px;
    margin-bottom: 25px;
  }

  .button {
    padding: 7px 30px;
    font-weight: 600;
    border-radius: 17px;
  }
}
