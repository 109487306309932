@use 'src/styles/flyout';

.bannerContainer {
  position: relative;
  background: #fffad1;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  & > span {
    font-size: 0.875rem;
    font-weight: 400;
  }

  .button {
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .close {
    position: absolute;
    right: 1rem;
    width: 1.4rem;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}
