@import 'src/styles/variables';

.lookup {
  :global(.lookup-select__control) {
    min-height: 35px;
    max-height: 150px;
    border-radius: 17px;
  }

  :global(.lookup-select__value-container) {
    max-height: 130px;
    overflow-y: scroll;
  }

  :global(.lookup-select__placeholder) {
    margin-top: -8px;
    color: var(--primary-color);
  }

  :global(.select__dropdown-indicator) {
    color: var(--primary-color);
  }

  :global(.lookup-select__indicator) {
    color: var(--primary-color);
  }

  :global(.lookup-select__single-value) {
    margin-top: 3px;
  }

  :global(.lookup-select__menu) {
    z-index: 100000;
  }

  .placeholder {
    display: inline-flex;
    align-items: flex-end;
    top: 6px;
    position: relative;
  }

  .placeholderMuiIcon {
    position: relative;
    top: -4px;
    left: -2px;
  }

  .placeholderIcon {
    position: relative;
    top: -6px;
    height: 12px;
    width: 12px;
    fill: var(--primary-color);
    margin-right: 5px;
  }
}

.error {
  > :global(.lookup-select__control) {
    border: 1px solid var(--error-color);
  }
}

.region {
  margin: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid $font-light-color;
  }

  .regionCheckbox {
    padding: 0 0 5px;

    .regionCheckboxLabel {
      font-weight: 700;
    }
  }
  .locations {
    list-style: none;
    margin: 0 0 10px;
    padding-left: 15px;
    .locationCheckbox {
    }
  }
}

.inactiveBadge {
  color: var(--white-color);
  background-color: var(--error-color);
  border-radius: 4px;
  display: inline-block;
  font-weight: 300;
  padding: 4px 5px 1px 5px;
  margin: -4px 0 -5px 10px;
}
