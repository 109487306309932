.container {
  display: flex;
  flex-direction: column;
  border: none;
  outline: none;
  padding: 0.7rem 0;
}

.label {
  display: block;
}

.input {
  height: 35px;
  border-radius: 17px;
  padding: 0 15px;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: var(--white-color);
  color: var(--primary-color);
  font-family: var(--default-font-family);
  font-size: var(--font-size-medium);

  &.error {
    border: 1px solid var(--secondary-color);
  }
}

.textArea {
  border: none;
  outline: none;
  background-color: var(--white-color);
  padding: var(--form-box-padding);
  display: block;
  width: 100%;
  box-sizing: border-box;
  color: var(--primary-color);
  font-family: var(--default-font-family);
  font-size: var(--font-size-medium);
}

.dropdown > div:first-of-type {
  border-radius: 17px;
}

.errorText {
  margin-top: 4px;
  font-size: 12px;
  color: var(--secondary-color);
}
