div.root {
  display: flex;
  flex-direction: column;
  padding: 0;

  .title {
    padding: 10px;
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 500;
  }

  .reminderBlock {
    border-radius: 8px;
    background-color: var(--secondary-light-color);
    text-align: center;
    padding: 10px 30px;

    > h3,
    p {
      font-size: 12px;
      margin: 0;
    }
  }

  .variableContent {
    padding: 15px 20px;
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;

    .messagePreview {
      display: flex;
      flex-direction: column;
      gap: 10px;

      h4 {
        text-align: center;
        margin-bottom: 0;
      }

      .messageWrapper {
        position: relative;
        background-color: var(--text-block-color);
        padding: 15px;
        border-radius: 8px;
        font-size: 12px;

        > hr {
          border: 0.5px solid #b3cdda;
        }

        .messageBody {
          white-space: pre-wrap;

          &.error {
            color: var(--error-color);
          }

          span {
            background-color: #ffffff80;
            padding: 4px 4px 2px;
            border-radius: 4px;

            &:global(.set) {
              font-weight: 600;
            }

            &:global(.unset) {
              color: var(--error-color);
            }
          }
        }

        .loading {
          position: absolute;
          top: 80px;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

.indicatorBackground {
  position: relative;
  &:before {
    position: absolute;
    content: '';
    height: 4px;
    background-color: #dae6ed;
    width: 100%;
    bottom: 0;
  }

  span.indicator {
    background-color: var(--primary-color);
    height: 4px;
  }
}

button.tab {
  text-transform: capitalize;

  &.selected {
    color: var(--primary-color);
  }
}

.dashedDivider {
  min-height: 1px;
  max-height: 2px;
  width: 100%;
  background-image: linear-gradient(
    to right,
    black 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 8px 1px;
  background-repeat: repeat-x;
  margin: 10px 0;
}

div.actions {
  box-shadow: 0px -5px 8px 0px #0000001a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;

  button.actionButton {
    background: var(--secondary-color);
    color: var(--white-color);
    padding: 6px 24px;
    border-radius: 20px;
    text-transform: capitalize;
    min-width: 75px;

    &:hover {
      background: var(--secondary-color);
      opacity: 0.8;
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.5;
      cursor: wait;
    }
  }

  button.backButton {
    color: var(--primary-color);
    text-transform: capitalize;
  }
}

.variablesList {
  list-style: none;
  padding: 0;
}

.attachment {
  width: 170px;
  margin-top: 10px;
}
