.container {
  position: relative;
  width: calc(100% - (var(--flyout-padding) * 2));
  margin-left: var(--flyout-padding);
  margin-right: var(--flyout-padding);
  margin-bottom: 10px;

  --border-radius: 3px;
  --cancel-color-column-width: 4px;

  border: none;
  border-radius: var(--border-radius);

  &:hover {
    box-shadow: 0 1px 6px #00000080;
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
  }
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  left: var(--cancel-color-column-width);
  top: 0;
  width: calc(100% - var(--cancel-color-column-width));
  background: var(--white-color);
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  padding-bottom: 1.75em;
  align-items: center;
}

.header {
  position: relative;
  width: 100%;
  height: 35px;

  .deleted {
    color: #ff4d4d;
  }
}

.headerLeft {
  position: absolute;
  top: 15px;
  left: 15px;
}

.headerRight {
  position: absolute;
  top: 15px;
  right: 15px;
}

.textRight {
  flex-grow: 0;
}

.smallText {
  color: var(--light-info-color);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
}

.showMoreContainer {
  display: flex;
  align-items: center;
  color: var(--show-more-button-color);
  position: absolute;
  right: 1em;
  bottom: 0.25em;

  span {
    margin-top: 0.3em;
  }
}
