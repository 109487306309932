
.dateFilterTypeTitle {
  margin-top: var(--flyout-padding);
  margin-left: var(--flyout-padding);
  margin-bottom: 10px;
}

.noEncounters {
  margin-bottom: 10px;
  margin-left: var(--flyout-padding);
  color: var(--light-info-color);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}

.loadMoreButton {
  display: flex;
  justify-content: center;

  > button {
    background-color: var(--active-color);
    color: var(--white-color);
  }
}
