@use 'src/styles/flyout';

.root {
  padding: 0 var(--flyout-padding) var(--flyout-padding);
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;

  &,
  * {
    box-sizing: border-box;
  }
}

.statsContainer {
  margin: 0 var(--flyout-padding);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 0.5rem;
  margin-top: 0.5rem;
  position: relative;

  // wrap after the 3rd element
  > button:nth-of-type(3n) {
    margin-right: 50px;
  }

  &.hide {
    display: none;

    &Empty {
      > button:nth-of-type(3n) {
        margin-right: unset;
      }
    }
  }

  & > .statsButton {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    color: #10365a;
    line-height: 1;
    padding: 0;

    &.enabled {
      color: #3268c5;
      cursor: pointer;
      border-bottom: 1px solid #3268c5;
    }

    &.hide {
      display: none;
    }

    .name {
      margin-right: 4px;
    }

    .number {
      font-size: 0.9em;
    }

    .icon {
      margin-top: -0.3rem;
      color: #8599ab;
    }
    .stats {
      left: -9999px;
      margin-right: -10rem;
      position: absolute;
      background-color: white;
      top: 2rem;
      padding: 1rem;
      z-index: 9999;
      box-shadow: -1px 1px 2px 1px rgb(0 0 0 / 20%);
      border-radius: 4px;

      max-width: 90%;

      & > div {
        width: 100%;
      }

      .time {
        color: #8599ab;
      }
    }
  }

  div:hover {
    .stats {
      left: 0;
    }
  }

  .name {
    font-weight: 600;
  }
}
