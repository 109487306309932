.container {
  width: calc(100% - 2px);
  height: 36px;
  border-radius: 17px;
  border: 1px solid hsl(0, 0%, 80%);
  display: grid;
  grid-template-columns: 30px 1fr 30px;

  button {
    border: none;
    background: transparent;

    &:focus,
    &:active {
      outline: 0;
    }
  }

  .buttonBack {
    margin-top: 2px;
    margin-left: 8px;
  }

  .buttonForward {
    margin-top: 3px;
    margin-left: -13px;

    svg {
      transform: rotate(180deg);
    }
  }

  .date {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
}
