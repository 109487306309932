.container {
  overflow-y: scroll;
  background-color: var(--white-color);

  .label {
    border-radius: 4px;
    background: var(--shift-bg-color-not-responded, #fffbf2);
    padding: 8px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: var(--primary-color, #103559);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 125%;
  }

  .list {
    display: flex;
    flex-direction: column;

    .loadingContainer {
      display: flex;
      justify-content: center;
    }

    .conversationInfo {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 10px 20px;
      color: var(--primary-color);
      border-bottom: 1px solid #dae6ed;
      margin: 1px;

      &.selected {
        background-color: var(--provider-payment-color-extra-shift-day);
        color: var(--white-color);
      }

      .nameAndDate {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .unreadInfo {
          display: flex;
          gap: 8px;
          text-overflow: ellipsis;
          max-width: calc(100% - 100px);
          overflow: hidden;
          white-space: nowrap;
          font-size: 15px;
          font-weight: 600;
          line-height: 125%;

          .dot {
            flex-shrink: 0;
          }
        }

        .name {
          font-size: 15px;
          font-weight: 500;
          line-height: 125%;
          text-overflow: ellipsis;
          max-width: calc(100% - 100px);
          overflow: hidden;
          white-space: nowrap;
        }

        .date {
          font-size: 12px;
          font-weight: 400;
          line-height: 125%;
        }
      }

      .pets {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: calc(100% - 100px);
        white-space: nowrap;
        font-weight: 400;

        .patient {
          &.inactiveOrDeceased {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
