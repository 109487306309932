@use 'src/styles/flyout';

.monthSelect {
  margin-top: 2rem;
}

.collectedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.25rem;
  background-color: var(--white-color);
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(16, 54, 90, 0.2);
}

.price {
  color: var(--primary-color);
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 0.4rem;

  &.grayedOut {
    color: var(--light-info-color);
  }
}

.listContainer {
  max-width: 400px;
  margin: auto;
}

.title {
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  color: rgba(var(--primary-color), 0.8);
}

.subtitle {
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 0.875rem;
  color: rgba(var(--primary-color), 0.8);
  margin-bottom: 20px;
  margin-top: 4px;
}

.disclaimer {
  color: var(--primary-color, #103559);
  padding: 4rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 125%;
}

ul.daysList {
  list-style: none;
  padding: 0;
  width: 100%;
  display: grid;
  font-size: 10px;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 0;
  gap: 12px;
}

li.dayHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

li.dayContainer {
  .day {
    position: relative;
    margin: auto;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: var(--white-color);
    background-color: var(--provider-payment-color-pto-day);

    > .dayNumber {
      padding-top: 3px;
      cursor: default;
    }

    > img {
      height: 1.2em;
    }

    &.futureDate {
      color: #10365a26;
      background-color: transparent;
    }

    &.noBonusEarned {
      background-color: var(--white-color);
      color: #000000;
    }

    &.bonusEarned {
      background-color: var(--provider-payment-color-goal-reached);
      color: #10365a;
    }

    .today {
      border: 3px var(--active-color) solid;
      border-radius: 50%;
      position: absolute;
      bottom: -8px;
    }

    .circularLoading {
      color: var(--provider-payment-color-goal-reached);
      position: absolute;
    }

    .circularLoadingBackground {
      color: #b3cdda;
      position: absolute;
    }
  }
}

.tooltipContent {
  padding: 3px 5px;
  color: #10365a;

  > img {
    margin-left: 0.2em;
    height: 1em;
    margin-bottom: -0.1em;
  }
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}
