.container {
  width: fit-content;
  height: 35px;
  border-radius: 17px !important;
  border: none;
  display: grid;
  opacity: 0.4;
  background: var(--white-color);
  cursor: default;

  &.active {
    opacity: 1;
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: 0;
  }

  padding-top: 10px;
  padding-left: 28px;
  padding-right: 28px;
}
