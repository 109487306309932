@use 'src/styles/flyout';

.yearSelect {
  margin-top: 2rem;
}

.collectedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2.25rem 2.25rem 2.25rem;
  margin-top: 6.5rem;
  position: relative;
  background-color: var(--white-color);
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(16, 54, 90, 0.2);

  .trophyIcon {
    width: 10rem;
    position: absolute;
    left: 50%;
    margin-left: -5rem;
    top: -6rem;
  }

  .price {
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 0.4rem;

    &.grayedOut {
      color: var(--light-info-color);
    }
  }

  .title {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    color: rgba(var(--primary-color), 0.8);
  }

  .subtitle {
    width: 100%;
    text-align: center;
    font-weight: 300;
    font-size: 0.875rem;
    color: rgba(var(--primary-color), 0.8);
    margin-bottom: 20px;
    margin-top: 4px;
  }

  .projectedEarnings {
    padding: 1rem 2rem;
    background: #48d2a0;
    color: #10365a;
    margin-top: 1rem;
    letter-spacing: 1px;
    border-radius: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;

    img {
      margin-right: 0.5em;
      height: 1.3em;
      margin-top: -0.1em;
    }

    strong {
      font-weight: 600;
    }
  }
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}
