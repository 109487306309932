@use 'src/styles/flyout';
@import 'src/styles/variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.options {
    display: flex;
    justify-content: space-between;
}

.ambientTypeFilter {
    width: 270px;
}

