@use 'src/styles/flyout';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}

.intro {
  order: 1;
  margin: 0;
}

.info {
  color: var(--staff-color-text);
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 10px;
  align-items: start;
  margin-top: 18px;
  order: 4;

  p {
    font-size: 12px;
    font-weight: var(--font-size-medium);
    margin: 0;
  }

  svg {
    display: block;
  }
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
  order: 2;
}
