@use 'src/styles/flyout';

.container {
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-template-columns: 1fr;
  height: calc(100vh - 100px);
  width: 100%;
}

.hidden {
  display: none;
}

.actions {
  display: flex;
  justify-content: space-between;

  margin-left: var(--flyout-padding);
  margin-right: var(--flyout-padding);
}

.loading {
  margin: var(--flyout-padding);
  text-align: center;
}

.error {
  margin: var(--flyout-padding);

  color: var(--white-color);
  background-color: var(--error-color);

  text-align: center;

  padding: 20px;
  border-radius: 5px;
}

.notSamePatientWarning {
  margin: var(--flyout-padding);

  color: var(--primary-color);
  background-color: var(--beige-color);

  text-align: center;

  padding: 20px;
  border-radius: 5px;

  button {
    margin-top: 10px;
  }
}

:global(.suki-container),
:global(.suki-loader) {
  box-sizing: border-box;
  width: 100vw;
  height: 100%;
  border-radius: 0;
  background: none;
}

:global(.suki-container) {
  position: relative;
}

:global(.suki-loader) {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #f0f4ef;
  border: none;
}

.externalNote {
  color: var(--primary-color);
  margin-bottom: 20px;
}
