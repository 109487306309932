.container {
  display: grid;
  background-color: var(--primary-color);
  padding: 3px;
  border-radius: 4px;
  width: 100%;
}

@for $i from 2 through 4 {
  .columns#{$i} {
    grid-template-columns: repeat($i, 1fr);
  }
}

.tabContainer:not(.activeTab) + .tabContainer:not(.activeTab) {
  border-left: 1px solid white;
}

.tabContainer {
  height: 28px;

  .tab {
    cursor: pointer;
    background: none;
    border: none;
    color: white;
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }
}

.activeTab {
  .tab {
    background-color: white;
    border-bottom: white;
    color: var(--primary-color);
  }
}

.tab:focus,
.tab:active {
  outline: none;
}
