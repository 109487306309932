.root {
  position: relative;
  width: 50%;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 15px;
  font-size: 12px;

  &.left {
    background-color: var(--white-color);
    align-self: flex-start;
  }

  &.right {
    background-color: var(--text-block-color);
    align-self: flex-end;
  }

  &.right.internalNote {
    background-color: var(--shift-bg-color-not-responded);
    border: 1px solid #ffeedded;
  }

  &.right.pending {
    background-color: var(--tooltip-button-bg-color);
    color: var(--pdf-preview-bg-color);
    border: 1px solid #b3cdda;
  }

  &.conversationBreak {
    align-self: flex-end;
    width: 100%;

    .line {
      position: absolute;
      width: 100%;
      border-color: var(--primary-color);
      border-top: 1px;
    }

    .banner {
      position: relative;
      left: 50%;
      top: -8px;
      transform: translateX(-50%);

      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      flex-shrink: 0;

      height: 16px;
      padding: 5px 20px;
      color: var(--white-color);
      background-color: var(--primary-color);

      border-radius: 100px;
      border: 1px solid var(--primary-color);

      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;

      svg {
        margin-top: -2px;
        fill: var(--font-grey-color);
      }
    }
  }

  .from {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 5px;
    gap: 5px;
    max-width: calc(100% - 200px);

    .templateIcon {
      font-size: 12px;
      padding: 2px;
      background: var(--warning-color);
      border-radius: 4px;
      margin-top: -4px;
    }
  }

  .sentAt {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .pendingBlurb {
    position: absolute;
    top: 10px;
    right: 102%;
    padding: 5px 10px 3px 10px;
    width: 50%;
    text-align: center;
    color: var(--white-color);
    background: var(--provider-payment-color-extra-shift-day);
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
  }

  .location {
    margin-bottom: 10px;
    display: flex;
    gap: 5px;
  }

  .divider {
    border: 0.5px solid #b3cdda;

    &.internalNote {
      border: 0.5px solid var(--warning-color);
    }
  }

  .body {
    font-size: 14px;
    overflow-wrap: break-word;
  }

  .imageBody {
    width: 25%;
    border-radius: 4px;
  }
}

.red {
  color: var(--secondary-color);
}

.logo {
  width: 14px;
  margin-bottom: 4px;
}
