.container {
  color: var(--primary-color);
  background: #b3cdda;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 1.25rem 1rem 1rem 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;

  .title {
    text-transform: uppercase;
    font-weight: 900;
  }

  .subtitle {
    font-style: italic;
    text-transform: lowercase;
    font-weight: 500;
  }
}

.childrenContainer {
  display: flex;
  gap: 1rem;
  flex-direction: row;
}
