.close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.content {
  padding: 20px 75px 50px;
  background: #f7f9fc;
}

.title {
  font-size: 24px;
  text-align: center;
}

.disclaimer {
  background-color: #fffbf2;
  border: 1px solid var(--warning-color);
  padding: 15px 30px;
  border-radius: 4px;

  .disclaimerTitle {
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
  }

  .disclaimerList {
    padding-left: 15px;
  }
}

.agreementTitle {
  font-size: 14px;
  text-transform: uppercase;
}

.agreementText {
  line-height: 24px;

  .input {
    border-radius: 15px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    margin: 0 3px;

    &.error {
      border: 1px solid var(--error-color);
    }
  }
}

.agreementData {
  display: flex;
  gap: 16px;

  .column {
    width: calc(50% - 8px);

    .columnTitle {
      font-size: 12px;
      text-transform: uppercase;
    }

    .item {
      display: flex;
      gap: 8px;
      padding: 10px 0;

      .value {
        flex-grow: 1;
        border-bottom: 1px solid #8fa0b1;
      }
    }
  }
}

.buttons {
  padding: 15px;
  background: #f7f9fc;
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: space-between;

  .cancel {
    color: var(--primary-color);
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      opacity: 0.8;
    }
  }

  .submit {
    background: var(--active-color);
    border-radius: 20px;
    padding: 10px 40px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      background: var(--active-color);
      opacity: 0.8;
      cursor: pointer;
    }
  }
}

.nameError {
  color: var(--error-color);
  font-size: 12px;
}
