.container {
  padding: 15px 10px;
}

.listContainer {
  max-width: 400px;
  margin: auto;
}

.title {
  margin-bottom: 5px;
}

ul.daysList {
  list-style: none;
  padding: 0;
  width: 100%;
  display: grid;
  font-size: 10px;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 25px;
  gap: 12px;
}

li.dayHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

li.day {
  position: relative;
  margin: auto;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 12px;

  > .dayNumber {
    padding-top: 3px;
  }
}

.tooltipContent {
  padding: 3px 5px;
}

.today {
  border: 3px var(--active-color) solid;
  border-radius: 50%;
  position: absolute;
  bottom: -8px;
}

.shiftDay {
  color: var(--white-color);
  background-color: var(--provider-payment-color-working-day);
}

.noShiftDay {
  color: var(--primary-color);
  background-color: var(--provider-payment-color-pto-day);
}

.extraShiftDay {
  color: var(--white-color);
  background-color: var(--provider-payment-color-extra-shift-day);
}

.criticalExtraShiftDay {
  color: var(--white-color);
  background-color: var(--provider-payment-color-critical-extra-shift-day);
}

.futureNoShiftDay {
  color: var(--provider-payment-color-future-pto-day);
}

.futureExtraShiftDay {
  color: var(--provider-payment-color-extra-shift-day);
  font-weight: 600;
}

.futureCriticalExtraShiftDay {
  color: var(--provider-payment-color-critical-extra-shift-day);
  font-weight: 600;
}

.past {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.2;
}

.progress {
  position: relative;
  width: 100%;
  height: 35px;
  background-color: var(--white-color);
  border-radius: 5px;
  overflow: hidden;
}

.progressGoalReached {
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 35px;
  background-color: var(--provider-payment-color-goal-reached);

  > div {
    position: absolute;
    top: 7px;
    font-size: 16px;
    width: 100%;
    height: 30px;
    text-align: center;
  }
}

.progressBar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10%;
  text-align: left;
  background-color: var(--primary-color);
}

.progressTextRight {
  font-size: 14px;
  position: absolute;
  right: 7px;
  top: 8px;
}

.lightColor {
  color: var(--light-info-color);
}

.progressTextLeft {
  color: var(--light-info-color);
  font-size: 16px;
  position: absolute;
  left: 7px;
  top: 8px;
}

.dayLetter {
  color: var(--light-info-color);
  margin-top: 10px;
}
