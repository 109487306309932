.container {
  background: white;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 9rem;

  .value {
    padding-top: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0;
    margin-bottom: 1rem;
  }

  .label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.125rem;
    letter-spacing: 0;
    margin-bottom: 0.5rem;
  }

  .subLabel {
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0;
  }
}
