@import 'src/styles/mixins';

.start-new-page {
  @include start-new-print-page;
}

.breakAfter {
  print-color-adjust: exact;
  break-after: column;
}

.description {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 400;
  margin: 0 1rem 1rem;

  & > strong {
    font-weight: 600;
  }

  & > i {
    font-style: italic;
  }
}

.printMessage {
  @extend .description;
  font-size: 0.875rem;
  margin: 1.5rem 0 0.5rem 1rem;
  font-style: italic;
}

.printOnly {
  display: none;
}

@media print {
  .printOnly {
    display: block;
  }
}

.mainBody {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 400;
  background: #f8f8f8;
  padding-left: 0.625rem;
  padding-top: 0.625rem;
  margin-top: -0.5rem;
  margin-bottom: 2rem;

  .topMessage {
    @extend .description;
    margin: 0 0 1rem 0;
  }

  .bottomMessage {
    @extend .description;
    margin: 1rem 0 0.5rem 0;
  }


  & > div {
    @extend .description;
    margin: 0 0 0.5rem 0;
  }
}

.detailedContainer {
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.75rem;
  margin-bottom: 2rem;

  & > div {
    @extend .description;
    margin-bottom: 0;
  }

  .topMessage {
    @extend .description;
    margin-bottom: 1rem;
    font-style: italic;
  }

  .bottomMessage {
    @extend .description;
    margin-top: 0;
    font-style: italic;
  }

  .list {
    margin: -0.5rem -1rem 0;
    padding: 0 2.5rem;

    li {
      @extend .description;
      margin: 0 0 0.5rem 0;
    }
  }
}
