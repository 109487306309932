.container {
  display: grid;
  grid-template-rows: max-content max-content 1fr;
  height: 100%;
  width: 100%;
}

.gridLine {
  display: grid;
  grid-template-columns: max-content repeat(7, 1fr);
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: 1px;
  padding-bottom: 1px;

  &.noAnalytics {
    grid-template-columns: 30px repeat(7, 1fr);
  }

  &:hover {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
  }
}

.grid {
  overflow: scroll;
  font-size: var(--font-size-small);
}

.statsBlock {
  display: grid;
  grid-template-columns: 35px 45px 20px 20px 20px;
  padding: 0 10px;

  > .role,
  > .count,
  > .underStaffedCount,
  > .overStaffedCount {
    margin-top: 2px;
  }
}

.columnsGroup {
  width: 100%;
  height: calc(100% - 4px);
  position: absolute;
  z-index: -1;
}

.columns {
  &:nth-child(odd) {
    background-color: var(--white-color);
  }
}

.dayHeader {
  margin-top: 10px;
  text-align: center;
  color: var(--staff-color-text);

  .date {
    font-weight: 500;
  }
}

.locationBlock {
  display: grid;
  grid-template-columns: 160px repeat(7, 1fr);
  margin-top: 10px;

  &:not(:first-child) {
    margin-top: 40px;
  }

  & > div {
    padding: 0 10px;
  }

  & > .statsColumn {
    display: grid;
    grid-template-columns: 7.5rem 1fr;
    grid-auto-rows: min-content;
    grid-row-gap: 1rem;

    & > span:nth-child(3),
    & > span:nth-child(2) {
      font-weight: 600;
    }

    span {
    }
  }
}

.locationBlock.noAnalytics {
  grid-template-columns: 32px repeat(7, 1fr);

  & > .statsColumn {
    grid-auto-rows: auto;
  }

  .locationName {
    writing-mode: vertical-rl;
    height: 100%;
    width: 1rem;
    text-align: center;
  }
}

.locationName {
  grid-column: 1 / -1;
  margin-bottom: 5px;
  font-size: var(--font-size-medium);
}

.shifts {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.shiftWrapper {
  background: none;
  border: none;
  color: #10365a;
  text-align: left;
  margin: 0;
  padding: 0;
}

.shift {
  border: none;
  box-shadow: none;
  display: flex;
  margin-bottom: 2px;
  background: #f7f9fc;
  position: relative;
  color: inherit;

  &:hover {
    cursor: pointer;
    padding-left: 2px;
  }

  .shiftText {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    font-size: 0.9em;

    & > div:first-child {
      font-weight: 600;
      padding-bottom: 2px;
    }

    span {
      margin-right: 0.7em;
    }

    .infoIconContainer {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 1.2rem;

      .noDoctorAssigned {
        display: flex;
        position: absolute;
        top: -0.5rem;
        box-shadow: 0 1px 6px #00000080;
        border-radius: 5px;
        left: -999rem;

        .noDoctorAssignedContainer {
          background: white;
          border-radius: 5px;
          font-size: 0.6rem;
          color: #8599ab;
          width: 8rem;
          padding: 0.5rem;
          z-index: 2;
          box-shadow: 2px 0 4px 0 #00000080;
        }

        .arrow {
          height: 1rem;
          width: 1rem;
          background: white;
          transform: rotate(45deg);
          margin: -0.5rem;
          margin-top: 0.85rem;
          z-index: 1;
          box-shadow: 0 0 4px 1px #00000080;
        }

        .title {
          color: #10365a;
          font-weight: bold;
          font-size: 1.05em;
          margin-bottom: 4px;
        }
      }

      &:hover {
        .noDoctorAssigned {
          left: 2.5rem;
        }
      }
    }
  }

  .shiftColor {
    width: 0.7rem;
    min-width: 0.7rem;
  }
}

.nothingFoundMsg {
  position: absolute;
  top: 50%;
  left: 50%;
}

.error {
  position: fixed;
  width: 80%;
  margin-left: 10%;
  top: 100px;
}
