.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin: 25px;

  .block {
    border: 1px solid var(--success-color);
    border-radius: 10px;
    padding: 5px 20px 10px;
    text-align: center;
    font-size: 14px;

    h1 {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .closeButton {
    background-color: var(--secondary-color);
    color: var(--white-color);
    text-transform: capitalize;
    font-size: 14px;
    border-radius: 20px;
    padding: 8px 16px;
  }
}
