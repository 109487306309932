.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: var(--primary-color);

  .closeIcon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--primary-color);

  .infoBlock {
    border-radius: 8px;
    border: 1px solid #ccc;
    background: var(--tooltip-button-bg-color);
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    gap: 10px;
    align-self: stretch;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .labelAndInput {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 2px;

      color: var(--primary-color);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .datePicker {
      input {
        padding: 10px 15px;
      }
    }

    .timeInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .timePicker {
      flex-grow: 2;
      input {
        padding: 10px 15px;
      }
    }

    .durationWithMinutes {
      display: flex;
      gap: 5px;
      align-items: center;

      .duration {
        max-width: 100px;

        input {
          padding: 10px 20px;
        }
      }

      .minutes {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
      }
    }

    .notes {
      width: 100%;
      min-height: 75px;
    }
  }
  .actionButtons {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;

    .cancel {
      color: var(--active-color, #ff4d4d);
    }

    .submit {
      color: var(--white-color, #fff);
      border-radius: 4px;
      background: #3268c5;
      display: flex;
      padding: 8px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &.disabled {
        opacity: 0.6;
      }
    }
  }

  .error {
    color: var(--active-color);
    padding-top: 4px;
  }
}
