.container {
  display: flex;
  padding: 14px 20px 15px 20px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--tooltip-button-bg-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.content {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-grow: 1;

  .name {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    color: var(--primary-color);
    text-decoration: none;
    cursor: pointer;
    margin-bottom: -7px;
  }

  .phone {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: -5px;
  }

  .petsWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
  }

  .pet {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    background: var(--secondary-light-color);
    border: none;
    border-radius: 4px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    &.inactive {
      opacity: 60%;
    }

    > svg {
      font-size: 14px;
    }
  }
}

.moreButton {
  background-color: var(--white-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  padding: 4px 10px;
  cursor: pointer;
}

.petDropdownWrapper {
  position: relative;
}

.petDropdown {
  z-index: 99;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: var(--white-color);
  padding: 15px 10px;
  align-items: flex-start;
  top: 28px;
  right: 0;
  min-width: 132px;
  border-radius: 4px;
  border: 1px solid #dce1e5;
  box-shadow: 0 2px 8px 0 #00000040;
  max-height: 70vh;
  overflow-y: scroll;
}

.deceasedIcon {
  border-radius: 50%;
  padding: 2px;
  background: #d0021b;
  font-weight: 600;
  color: var(--white-color);
  height: 12px;
  width: 12px;
  display: inline-block;
  line-height: 12px;
  text-align: center;
}

.tooltip {
  display: flex;
  align-items: center;
  gap: 5px;

  > svg {
    font-size: 14px;
  }
}

.unreadButton {
  background: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  padding: 10px 15px;
  border-radius: 20px;
  color: var(--white-color);
  font-weight: 500;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.8;
    color: var(--light-info-color);
    border: 1px solid var(--light-info-color);
    background-color: var(--white-color);
  }
}

.readButton {
  background-color: var(--white-color);
  color: var(--secondary-color);

  &:disabled {
    opacity: 0.8;
    color: var(--light-info-color);
    border: 1px solid var(--light-info-color);
  }
}
