.label {
  line-height: 20px;
}

:global(.lookup-select--is-disabled) {
  &.select :global(.lookup-select__control--is-disabled) {
    background-color: #fff;
    border-color: #ccc;

    :global(.lookup-select__dropdown-indicator) {
      display: none;
    }
  }
}

.select {
  :global(.lookup-select__placeholder) {
    margin-top: unset;
    line-height: 1;
  }

  :global(.lookup-select__multi-value__label) {
    font-size: 14px;
    line-height: 1;
    font-weight: var(--font-weight-regular);
    padding: 0;
  }

  :global(.lookup-select__multi-value) {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0 8px;
    box-sizing: border-box;
  }

  :global(.lookup-select__multi-value__remove) {
    padding: 0;
  }
}
