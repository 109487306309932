@use 'src/styles/flyout';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 35px 25px;
  row-gap: 10px;

  .title {
    color: var(--primary-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .notEnabled {
    display: flex;
    height: 75px;
    padding: 10px;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background: var(--warning-bg-color);
    color: #103559;

    text-align: center;
    font-size: 14px;
  }

  .description {
    color: var(--primary-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 0;

    .bold {
      font-weight: 600;
    }
  }

  .date {
    margin-top: 30px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .walkInsList {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-bottom: 20px;
  }

  .noWalkIns {
    display: flex;
    height: 75px;
    padding: 10px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    background: var(--white-color);
  }
}
