.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  iframe {
    border: none;
    width: 100vw;
    height: 100vh;

    body {
      margin: 0;
    }
  }
}
