.patientName {
  font-size: 18px;
  font-weight: 600;
}

.badge {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  color: var(--white-color);

  &.planName {
    background: #10365a;
  }

  &.patientInactive {
    background: #8fa0b1;
  }

  &.patientDeceased {
    background: #8fa0b1;
  }

  &.cancelled {
    background: #d0021b;
  }

  &.expired {
    background: #8fa0b1;
  }

  &.enabled {
    color: var(--white-color);
    background-color: var(--success-color);
    font-weight: 600;
  }

  &.disabled {
    color: var(--primary-color);
    font-weight: 600;
    background-color: #dce1e5;
  }

  &.addOn {
    color: var(--primary-color);
    font-weight: 400;
    background-color: var(--beige-color);
    padding: 2px 8px 0 8px;
  }
}

.footer {
  position: absolute;
  box-shadow: -10px 0 6px 0 rgba(0, 0, 0, 0.25);
  width: 100vw;
  height: 90px;
  bottom: 0;

  display: flex;
  justify-content: space-between;

  button {
    margin: 30px 20px 0 0;
    font-weight: 600;
    text-transform: uppercase;
  }

  .back {
    color: var(--secondary-color);
    background-color: transparent;
  }

  .next {
    color: var(--white-color);
    background-color: var(--secondary-color);
  }

  .cancel {
    color: var(--secondary-color);
    background-color: transparent;
    border: 1px solid var(--secondary-color);
  }
}
