@use 'src/styles/flyout';

.container {
  overflow-y: scroll;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}

.title {
  margin: var(--flyout-padding);
  margin-bottom: 0;
  font-size: var(--font-size-large);
}

.finished {
  margin: var(--flyout-padding);
}
