@use 'src/styles/flyout';

.root {
  padding: 0 var(--flyout-padding) var(--flyout-padding);
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;

  &,
  * {
    box-sizing: border-box;
  }
}

.flyoutBlock {
  display: flex;
  align-items: center;
}

.divider {
  margin: 30px 15px 0;
  width: 100%;
  border: 0.5px solid var(--font-grey-color);

  &.invoices {
    margin: 15px 15px 25px;
  }
}

.tabs {
  margin: 15px;
  width: fit-content;
  border-radius: 50px;
  box-shadow: 0 4px 10px 0 #00000026;

  .indicator {
    display: none;
  }

  .tab {
    flex-direction: row;
    gap: 10px;
    align-items: center;
    min-height: unset;
    margin: 10px;
    padding: 6px 12px 3px;
    color: var(--primary-color);

    > svg {
      height: 22px;
      margin-bottom: 2px;
      > path {
        fill: var(--primary-color);
      }
    }
  }

  .label {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .badge {
    background: var(--font-light-color, #dae6ed);
    border-radius: 10px;
    width: 16px;
    height: 16px;
    display: inline-block;
  }

  button.selected {
    border-radius: 50px;
    background: var(--font-light-color, #dae6ed);
    color: var(--provider-payment-color-extra-shift-day);

    > svg > path {
      fill: var(--provider-payment-color-extra-shift-day);
    }

    .badge {
      background: var(--white-color);
    }
  }
}

.strong {
  font-weight: 600;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
