@import './variables';

:root {
  --default-font-family: #{$font-family};
  --default-font-weight: #{$font-weight-regular};
  --default-font-size: #{$font-size-medium};

  font-size: var(--default-font-size);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font-family);
  color: var(--primary-color);

  --font-weight-light: #{$font-weight-light};
  --font-weight-regular: #{$font-weight-regular};
  --font-weight-medium: #{$font-weight-medium};
  --font-weight-bold: #{$font-weight-bold};

  --font-size-extra-small: #{$font-size-extra-small};
  --font-size-small: #{$font-size-small};
  --font-size-medium: #{$font-size-medium};
  --font-size-large: #{$font-size-large};

  // colors
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --secondary-light-color: #{$secondary-light-color};
  --success-color: #{$success-color};
  --error-color: #{$error-color};
  --error-color-alt: #{$error-color-alt};
  --warning-color: #{$warning-color};
  --warning-color-alt: #{$warning-color-alt};
  --beige-color: #{$beige-color};
  --white-color: #{$white-color};
  --black-color: #{$black-color};
  --color: var(--primary-color);
  --dark-green-color: #{$dark-green-color};
  --highlight-yellow-color: #{$highlight-yellow-color};

  --light-info-color: #{$light-info-color};
  --text-block-color: #{$text-block-color};
  --status-color: #{$status-color};
  --active-color: #{$active-color};
  --warning-bg-color: #{$warning-bg-color};
  --font-grey-color: #{$font-grey-color};

  --navigation-button-color: #{$navigation-button-color};
  --navigation-button-icon-size: #{$navigation-button-icon-size};
  --navigation-button-active-bg-color: #{$navigation-button-active-bg-color};

  --flyout-padding: #{$flyout-padding};

  --date-filter-color-all: #{$date-filter-color-all};
  --date-filter-color-past: #{$date-filter-color-past};
  --date-filter-color-today: #{$date-filter-color-today};
  --date-filter-color-future: #{$date-filter-color-future};

  --appointment-log-filter-color-all: #{$appointment-log-filter-color-all};
  --appointment-log-filter-color-cancels: #{$appointment-log-filter-color-cancels};
  --appointment-log-filter-color-reschedules: #{$appointment-log-filter-color-reschedules};
  --appointment-log-filter-color-no-shows: #{$appointment-log-filter-color-no-shows};
  --appointment-log-filter-color-blockoff: #{$appointment-log-filter-color-blockoff};

  --shift-bg-color-not-responded: #{$shift-bg-color-not-responded};
  --shift-bg-color-confirmed: #{$shift-bg-color-confirmed};
  --shift-bg-color-negative: #{$shift-bg-color-negative};

  --staff-color-text: #{$staff-color-text};
  --staff-color-manager: #{$staff-color-manager};
  --staff-bgcolor-manager: #{$staff-bgcolor-manager};
  --staff-color-doctor: #{$staff-color-doctor};
  --staff-bgcolor-doctor: #{$staff-bgcolor-doctor};
  --staff-color-training-doctor: #{$staff-color-training-doctor};
  --staff-bgcolor-training-doctor: #{$staff-bgcolor-training-doctor};
  --staff-color-nurse: #{$staff-color-nurse};
  --staff-bgcolor-nurse: #{$staff-bgcolor-nurse};
  --staff-color-assistant: #{$staff-color-assistant};
  --staff-bgcolor-assistant: #{$staff-bgcolor-assistant};
  --staff-color-carecoordinator: #{$staff-color-carecoordinator};
  --staff-bgcolor-carecoordinator: #{$staff-bgcolor-carecoordinator};
  --staff-color-overstaffed: #{$staff-color-overstaffed};
  --staff-color-understaffed: #{$staff-color-understaffed};
  --staff-color-no-role: #{$staff-color-no-role};

  --provider-payment-color-working-day: #{$provider-payment-color-working-day};
  --provider-payment-color-pto-day: #{$provider-payment-color-pto-day};
  --provider-payment-color-extra-shift-day: #{$provider-payment-color-extra-shift-day};
  --provider-payment-color-critical-extra-shift-day: #{$provider-payment-color-critical-extra-shift-day};
  --provider-payment-color-goal-reached: #{$provider-payment-color-goal-reached};
  --provider-payment-color-future-pto-day: #{$font-grey-color};
  --provider-payment-color-tooltip-border: #{$font-grey-color};

  --show-more-button-color: #{$show-more-button-color};

  --pdf-preview-bg-color: #{$pdf-preview-bg-color};
  --form-box-padding: #{$form-box-padding};

  --tooltip-button-bg-color: #{$tooltip-button-bg-color};
}
