.adminMessageContainer {
  display: flex;
  justify-content: space-around;
}

.disabled {
  background: rgba(240, 81, 82, 0.2);
}

.enabled {
  background: rgba(72, 210, 160, 0.15);
}

.adminMessage {
  font-style: italic;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  justify-content: space-around;
  padding: 4px;
  border-radius: 4px;
  align-items: center;
  gap: 5px;

  span {
    display: inline-flex;
    align-items: center;
    line-height: 1rem;
    font-size: 0.875rem;
  }
}

.checkCircleIcon {
  position: relative;
  color: var(--success-color);
}

.cancelCircleIcon {
  position: relative;
  color: var(--secondary-color);
}
