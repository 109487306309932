.container {
  width: 100%;
}

.table {
  width: calc(100% + 1rem);
  margin-left: -1rem;

  td,
  th,
  tr {
    border: none;
    color: #10365a;
    padding: 0.3rem 0.5rem;
  }

  th {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.125rem;
    letter-spacing: 0;
  }

  .headerCell {
    width: 120px;
  }

  .grossProductionCell {
    width: 135px;
    padding-right: 1rem;
  }

  .productionRate {
    display: inline-block;
    background: rgba(244, 215, 198, 0.5);
    padding: 0.625rem 0.625rem;
    border-radius: 2px;
    font-weight: 600;
  }

  .highlightedCell {
    background: #fef6e799;
  }

  .highlightedCell + .highlightedCell {
    border-left: 1px solid #ffc0434d;
  }

  .spacingRow {
    height: 1rem;

    td {
      padding: 0;
    }
  }

  .oddRow {
    background: #f8f8f8;
  }

  .noteRow {
    margin-top: -0.5rem;
    display: block;
  }

  .noteCell {
    font-size: 0.75rem;
  }

  .firstSummaryRow {
    border-top: 1.71px solid #10365a;
  }

  .targetRow {
    background: #f4d7c6;
  }

  .negativeNumber,
  .positiveNumber {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0;
    text-align: right;
  }

  .negativeNumber {
    color: #a41402;

    &:before {
      content: '-';
    }
  }

  .positiveNumber {
    color: #0b7340;

    &:before {
      content: '+';
    }
  }
}

.rowGroup {
  position: relative;

  & > .cellContainer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    border-right: 3px solid transparent;

    .labelContainer {
      display: flex;
      justify-content: stretch;
      width: 1.5rem;

      .label {
        transform: rotate(180deg);
        writing-mode: vertical-rl;

        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.125rem;
        letter-spacing: 0;
        text-align: right;
      }

      .underline {
        margin-left: 1px;
        width: 2px;
      }
    }
  }
}

.footer {
  margin-left: 1.5rem;
  margin-top: 2rem;

  span {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0;
  }
}
