$not-filled-color: #dae6ed;

@keyframes animationPercentLabel {
  0% {
    opacity: 0;
  }
  47% {
    opacity: 0;
  }
  53% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animationLastMilestoneIcon {
  0% {
    top: 0.8em;
    width: 2.8em;
    height: 2.8em;
  }
  44.5% {
    top: 0.8em;
    width: 2.8em;
    height: 2.8em;
  }
  55.5% {
    top: 0.55em;
    width: 1.2em;
    height: 1.2em;
  }
  100% {
    top: 0.55em;
    width: 1.2em;
    height: 1.2em;
  }
}

@keyframes animationFirstMilestoneIcon {
  0% {
    top: 0.55em;
    width: 2em;
    height: 2em;
  }
  44.5% {
    top: 0.55em;
    width: 2em;
    height: 2em;
  }
  55.5% {
    top: 0.45em;
    width: 0.9em;
    height: 0.9em;
  }
  100% {
    top: 0.45em;
    width: 0.9em;
    height: 0.9em;
  }
}

.root {
  position: relative;
  background-color: $not-filled-color;
  height: 2.5em;
  font-size: 1.3rem;
  border-radius: 4px;
  margin-bottom: 0.5em;
  margin-right: 1.375em;

  &::after {
    font-size: 1.3em;
    z-index: 2;
    content: '';
    position: absolute;
    right: -1.375em;
    top: -0.53em;
    width: 0;
    height: 0;
    border-top: 1.5em solid transparent;
    border-left: 2em solid $not-filled-color;
    border-bottom: 1.5em solid transparent;
  }
}

.hideLabel {
  .bar span {
    display: none;
  }
}

.hidden {
  display: none;
}

.milestone {
  --milestone-position: 0;
  --milestone-color: $not-filled-color;
  --milestone-right: 100%;
  --milestone-delay: 0;
  --milestone-transition: 2s;
  position: absolute;
  width: 3em;
  height: 3em;
  margin-top: -0.5em;
  background: $not-filled-color;
  border-radius: 50%;
  display: flex;
  overflow: visible;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  transition: opacity 2s;
  left: var(--milestone-position);

  &::after {
    top: 0;
    left: 0;
    bottom: 0;
    right: var(--milestone-right);
    content: '';
    display: block;
    position: absolute;
    background: var(--milestone-color);
    transition: right 0.3s;
    transition-delay: var(--milestone-delay);
    border-radius: 50%;
  }
}

.lastMilestone {
  width: 4.25em;
  height: 4.25em;
  margin-top: -1.125em;
}

.milestoneBackgroundContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  overflow: hidden;
}

.milestoneBackground {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(15, 15, 15, 0.34);
}

.milestoneIcon {
  position: absolute;
  z-index: 1;
  font-size: 1em;
  width: 1em;
  height: 1em;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  img {
    width: 100%;
    height: 100%;
  }
}

.animationFirstMilestoneIcon {
  animation-name: animationFirstMilestoneIcon;
}

.animationLastMilestoneIcon {
  animation-name: animationLastMilestoneIcon;
}

.milestoneLoading {
  animation-play-state: paused;
}

.milestonePercent {
  animation: animationPercentLabel 5s ease-in-out 0s infinite alternate;
  z-index: 1;
  font-weight: 600;
  position: absolute;
}

.milestonePercentBig {
  font-size: 1.313em;
  bottom: 0.5em;
}

.milestonePercentSmall {
  font-weight: 500;
  font-size: 1em;
  bottom: 0.25em;
}

.milestoneLabel {
  position: absolute;
  top: calc(100% + 0.5rem);
  font-size: 0.688em;
  font-weight: 500;
}

.bar {
  --bar-width: 0;
  --bar-color: #b3cdda;
  --bar-start-color: #dae6ed;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    var(--bar-start-color),
    var(--bar-color)
  );
  max-width: 100%;
  width: var(--bar-width);
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  transition: width 2s;

  & span {
    display: block;
    font-size: 0.75em;
    line-height: 1;
    color: var(--primary-color);
    font-weight: 500;
    position: absolute;
    top: calc(50% - 0.25em);
    left: 0.938em;
  }

  & .success span {
    color: var(--white-color);
  }
}

.success {
  .bar {
    background-color: var(--primary-color);
  }
}
