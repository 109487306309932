@use 'src/styles/flyout';

.container {
  overflow-y: scroll;
  display: grid;
  grid-template-rows: 1fr max-content;
}

.list {
  margin: var(--flyout-padding);
  margin-bottom: 3px;
  overflow-y: scroll;
}

.listItem {
  position: relative;
  top: 0;
  left: 0;
  margin: 12px 8px;
  padding: var(--form-box-padding);
  background-color: var(--white-color);
}

.noMedications {
}

.medicationNote {
  color: var(--light-info-color);
  background-color: transparent;
  padding: var(--form-box-padding);
  margin-top: 20px;
}
