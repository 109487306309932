.root {
  position: relative;

  .searchInput {
  }

  .searchResults {
    &.hidden {
      display: none;
    }

    position: absolute;
    width: 800px;

    border-radius: 8px;
    background: var(--white-color, #fff);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5);
    z-index: 10;
    margin-top: 3px;

    th {
      color: var(--primary-color);
      background: var(--beige-color);
      border: none;
      height: 45px;
      padding: 0 8px;

      svg {
        color: #b3cdda;
        position: absolute;
      }
    }

    tr {
      border: none;
      border-bottom: 1px solid #f8f1eb;
      cursor: pointer;
      height: 45px;
      &:hover {
        background: #e0ebfd;
      }

      &.disabled {
        &:hover {
          background: var(--white-color);
        }
      }
    }

    td {
      padding: 8px;
      color: var(--primary-color);

      &.disabled {
        color: #525659;
      }

      &.italic {
        font-style: italic;
      }

      &.notOptedIn {
        color: var(--error-color);
      }

      &.phone {
        min-width: 100px;
      }

      &.noResults {
        text-align: center;
        padding: 24px 8px;
      }
    }
  }

  .patient {
    &.inactive {
      color: var(--light-info-color);
      font-style: italic;
    }
    &.deceased {
      color: var(--error-color);
      font-style: italic;
    }
  }
}
