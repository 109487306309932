.lookup {
  :global(.visit-select__control) {
    min-height: 35px;
    max-height: 150px;
    border-radius: 18px;
  }

  :global(.visit-select__value-container) {
    max-height: 130px;
    overflow-y: scroll;
  }

  :global(.visit-select__placeholder) {
    color: var(--primary-color);
  }

  :global(.select__dropdown-indicator) {
    color: var(--primary-color);
  }

  :global(.visit-select__indicator) {
    color: var(--primary-color);
  }

  :global(.visit-select__single-value) {
    margin-top: 3px;
  }

  :global(.visit-select__menu) {
    z-index: 100000;
  }

  :global(.visit-select__option) {
    &:first-child:not(:last-child) {
      padding: 15px 15px 10px;
    }
    &:last-child:not(:first-child) {
      padding: 10px 15px 15px;
    }
    padding: 10px 15px;
  }

  .placeholder {
    display: inline-flex;
    align-items: flex-end;
    left: 6px;
    top: 2px;
    position: relative;
    color: #10365a;
    opacity: 0.5;
  }
}

.strong {
  font-weight: 600;
}
