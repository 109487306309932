.root {
  display: grid;
  grid-template-columns: 1fr max-content;
  height: 55px;
  background-color: var(--white-color);

  .delete {
    color: var(--light-info-color);
    align-self: center;
  }
}

.logo {
  max-width: 37px;
  max-height: 37px;
  width: 100%;
}

.info {
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(3, max-content) 1fr;
  grid-column-gap: 12px;
  align-items: center;
  font-weight: var(--font-weight-medium);
  line-height: 1;
  padding: 8px 12px;

  p {
    margin: 0;
  }
}

.menu {
  .menuItem {
    color: var(--primary-color);

    &.delete {
      color: var(--secondary-color);
    }

    &:hover {
      color: var(--white-color);
      background-color: var(--primary-color);
    }

    &.disabled {
      color: #a6b3c1;

      &:hover {
        background-color: var(--white-color);
        cursor: default;
      }
    }
  }
}

div.chip {
  background-color: #dfebf5;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 14px;

  &.smallChip {
    border-radius: 3px;
  }
}

.tooltip {
  font-size: 12px;
}

.dialog {
  padding: 5px 10px 10px;

  .dialogTitle {
    text-align: center;
    color: var(--secondary-color);
    font-size: 20px;
  }

  .dialogText {
    color: var(--primary-color);
  }

  .strong {
    font-weight: 600;
  }

  .actions {
    padding: 15px;

    .cancel {
      color: var(--secondary-color);
      font-weight: 600;
    }

    .submit {
      background-color: var(--secondary-color);
      color: var(--white-color);
      border-radius: 50px;
    }
  }
}
