@import 'styles/styles';
@import 'styles/custom-properties';

body,
html {
  margin: 0;
  padding: 0;
}

#root {
  width: 100vw;
  height: 100vh;
  position: absolute;
}
