@use 'src/styles/flyout';

.container {

  margin: 0;
  padding: 0;



  li {
    list-style: none;
    display: grid;

    cursor: pointer;

    grid-template-columns: max-content 1fr 1fr max-content;
    grid-column-gap: 10px;

    background-color: var(--white-color);

    border: 1px solid var(--white-color);
    border-radius: 4px;

    padding: 10px;
    margin-bottom: 10px;

    div {
      display: flex;
      align-items: center;
      padding-top: 2px;

      &:first-child {
        width: 150px;
      }
    }

    &.processed {
      border: 1px solid #0E8852;
      background: var(--Success-Light-Green, #F2FBED);
    }

    .icon {
      margin-right: 5px;
      margin-top: -3px;
    }
  }
}
