.root {
  padding-top: 0;

  .infoGroup {
    display: flex;
    padding: 6px;
    align-items: flex-start;
    gap: 10px;

    > svg {
      flex-shrink: 0;
    }

    .infoSection {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;

      .strong {
        font-weight: 600;
      }

      &.header {
        font-weight: 500;
      }
    }
  }

  .petNameAndLink {
    display: flex;
    column-gap: 5px;

    .patientLink {
      color: var(--show-more-button-color);
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: auto;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
      padding: 0;
      cursor: pointer;
    }
  }
}
