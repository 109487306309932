@import 'src/styles/mixins';

@page {
  margin: 0;
}

.new-page {
  @include start-new-print-page;
}

.reportContainer {
  $padding: 2rem;
  width: calc(100% - 2 * $padding);
  color: var(--primary-color);
  padding: $padding $padding 0;
  print-color-adjust: exact;
  break-after: column;

  &:first-of-type {
    height: 1028px;
    @media print {
      height: 97%;
    }
    background: #f9f8f8;
  }

  .loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 8rem;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 8rem;

    .title {
      font-weight: 900;
      font-size: 2.125rem;
      text-transform: uppercase;
    }

    .subtitle {
      font-weight: 400;
      font-size: 1.375rem;

      .divider {
        font-size: 1.71rem;
        margin: 0 1rem;
      }
    }
  }

  .workSummaryContainer {
    width: 100%;
    display: flex;
    gap: 2px;

    div {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 1rem;
      background: #10365a;
      color: white;

      span {
        padding-top: 0.25rem;
      }
    }
  }

  .smallColumn,
  .largeColumn {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
  }

  .smallColumn {
    flex: 1;
  }

  .largeColumn {
    flex: 2;
  }

  .grid {
    display: flex;
    gap: 1rem;
  }
}

.extraShiftsTitleContainer {
  @include start-new-print-page;

  margin: 0 2rem -2rem;
  print-color-adjust: exact;
}

.extraShiftBonusContainer {
  background: #f1f6f8;
  padding: 2rem;
  margin: 1rem 1rem 2rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .extraShiftBonus {
    font-size: 1.125rem;
    font-weight: 600;
  }

  .label {
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.extraShiftBonusContainer_v3 {
  &.regular {
    background: #F1F6F8;
  }

  &.critical {
    background: #F3DFF6;
  }
  padding: 2rem 6rem;
  margin: 1rem 1rem 2rem;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  .valueWithLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    .value {
      font-size: 1.125rem;
      font-weight: 600;
    }

    .label {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}

.extraShiftDayFooter {
  margin-left: 1.5rem;
  margin-top: 2rem;

  span {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0;
  }
}
