.root {
  display: flex;
  column-gap: 20px;
  padding: 0;

  .leftSide {
    flex-grow: 1;
    width: calc(50% - 10px);
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: var(--tooltip-button-bg-color);
    padding: 0;

    .leftSideContent {
      display: flex;
      flex-direction: column;
      padding: 0;
      flex-grow: 1;
      overflow-y: scroll;

      .title {
        font-size: 16px;
        color: var(--primary-color);
        padding: 16px 24px 6px;
        font-weight: 600;
      }

      .tabContent {
        padding: 15px;
        flex-grow: 1;
        overflow-y: scroll;
        color: var(--primary-color);

        .templateList {
          padding: 0;
          list-style: none;

          > li {
            background: var(--white-color);
            border-radius: 4px;
            padding: 15px;
            margin-bottom: 10px;

            &.selectedTemplate {
              background-color: var(--secondary-color);
              color: var(--white-color);

              &:hover {
                opacity: 1;
              }
            }

            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  .rightSide {
    flex-grow: 1;
    border-radius: 4px;
    background-color: var(--tooltip-button-bg-color);
    padding: 0;
    width: calc(50% - 10px);
    overflow-y: scroll;

    .templateWrapper {
      padding: 15px;
      color: var(--primary-color);

      .templateTitle {
        font-size: 20px;
        font-weight: 600;
      }

      .subtitle {
        font-weight: 600;
        margin-bottom: 0;
      }

      .preview {
        white-space: pre-wrap;

        > span {
          background-color: var(--white-color);
          color: var(--error-color);
          padding: 4px 4px 2px;
          border-radius: 4px;
        }
      }

      .divider {
        border: 0.5px solid #b3cdda;
      }
    }
  }
}

.indicatorBackground {
  position: relative;
  &:before {
    position: absolute;
    content: '';
    height: 4px;
    background-color: #dae6ed;
    width: 100%;
    bottom: 0;
  }

  span.indicator {
    background-color: var(--primary-color);
    height: 4px;
  }
}

button.tab {
  text-transform: capitalize;

  &.selected {
    color: var(--primary-color);
  }
}

.noTemplate {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.actions {
  box-shadow: 0px -5px 8px 0px #0000001a;

  button.actionButton {
    background: var(--secondary-color);
    color: var(--white-color);
    padding: 6px 24px;
    border-radius: 20px;
    text-transform: capitalize;

    &:hover {
      background: var(--secondary-color);
      opacity: 0.8;
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}
