@use 'src/styles/flyout';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.noFilter {
  grid-template-rows: max-content 1fr;
}

.oneFilter {
  grid-template-rows: max-content max-content 1fr;
}

.twoFilters {
  grid-template-rows: max-content max-content max-content 1fr;
}

.hint {
  text-align: center;
}
