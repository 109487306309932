@use 'src/styles/flyout';
@import 'src/components/LookupSelect/LookupSelect.module';

.container {
  display: grid;
  grid-template-columns: 200px 2fr 1fr max-content max-content;
  grid-column-gap: 10px;
  padding: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  background-color: var(--vs-ext-flyout-bg-color);
}

.checkboxColumn {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
