@use 'src/styles/flyout';

.container {
  padding-top: 0.5rem;
}

.actions {
  display: flex;
  margin-top: 1.5rem;
  padding-bottom: 2rem;
}

.button {
  color: var(--white-color);
  margin-left: auto;

  &.submit {
    background-color: var(--secondary-color);
  }

  &.succeeded {
    background-color: var(--success-color);
    opacity: 1;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-column-gap: 1rem;
    padding-top: 0;
  }
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}
