.progressStep {
  background-color: unset;
  border: none;
  border-bottom: solid 3px #dce3ea;
  height: 45px;
  opacity: 0.4;
  color: var(--primary-color);

  &.enabled {
    opacity: 0.6;
    cursor: pointer;
  }

  &.active {
    opacity: 1;
    border-bottom: solid 3px var(--primary-color);
  }

  &:focus,
  &:active {
    outline: none;
  }
}
