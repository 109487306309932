.container {
  background-color: var(--white-color);
  padding: 11px 12px 12px;
  color: var(--primary-color);
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: stretch;
  align-items: center;

  strong,
  span {
    display: block;
  }

  strong {
    font-size: 14px;
    margin-bottom: 7px;
    line-height: 15px;
  }

  span {
    line-height: 18px;
    font-size: 12px;
    color: var(--staff-color-text);
  }
}

.button {
  color: var(--white-color);
  background-color: var(--bg);
}

.linkButton {
  --bg: var(--primary-color);
}

.hideButton {
  --bg: var(--staff-color-text);
  margin-left: 9px;
}

.content {
  flex-grow: 1;
}

.actions {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
}

.infoIcon {
  position: relative;
  top: 7px;
  margin-left: 5px;
  color: var(--light-info-color);
}
