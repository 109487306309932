@import 'src/styles/mixins';

div.root {
  width: calc(100% - 40px);

  background-color: var(--tooltip-button-bg-color);
  color: var(--primary-color);

  .title {
    text-align: center;
    font-size: 20px;
  }

  strong {
    font-weight: 600;
  }

  ul {
    background-color: var(--white-color);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    min-height: 60px;

    font-size: 14px;

    margin: 0;
    padding: 10px 15px;

    li {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 80px 30px;
      padding: 7.5px 0;
      margin: 0;

      .invoiceLink {
        cursor: pointer;
      }

      .amount {
        text-align: right;
      }

      .negative {
        color: var(--error-color);
      }

      .refund {
        position: absolute;
        background: transparent;
        border: none;
        margin-top: -7px;
        cursor: pointer;
      }

      svg {
        color: var(--light-info-color);
      }

      &.current {
        border-top: 1px solid #8a8f99;
        font-weight: 600;
      }

      .loading {
        margin: 20px;
      }

      .infoIcon {
        position: absolute;
        margin-top: -4px;
        margin-left: 7px;
      }
    }
  }

  .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    background: none;
    border: none;
  }
}

.tooltip {
  font-size: 14px;
  display: inline-block;
  text-align: center;
}
