@use 'src/styles/flyout';

.root {
  padding-bottom: 30px;
}

.row {
  display: grid;
  grid-template-columns: 90px 1fr 80px 55px 50px;
  grid-column-gap: 10px;
  height: 46px;
  box-sizing: border-box;
  padding: 0 20px;
  margin-bottom: 6px;
  align-items: center;

  > span,
  > strong,
  > h4 {
    box-sizing: border-box;
    display: block;
    font-size: 14px;
    line-height: 1;
    font-weight: var(--font-weight-medium);
  }

  > h4 {
    margin: 0 0 -5px;
    color: var(--light-info-color);
  }

  > strong {
    font-weight: var(--font-weight-bold);
  }
}

.section {
  margin-top: 20px;
  font-size: 12px;

  &:first-child {
    margin-top: 0;
  }
}

.inputContainer {
  position: relative;
}

.amountInputContainer {
  position: relative;
  &:before {
    content: '$';
    position: absolute;
    top: 9px;
    left: 10px;
  }
}

.creditAmountInput {
  min-height: 35px;
  max-height: 150px;
  border-style: solid;
  border-radius: 17px;
  border-width: 1px;
  border-color: hsl(0, 0%, 80%);
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;
}

.button {
  color: var(--white-color);
}

.addCredit {
  background-color: var(--secondary-color);
  padding-left: 20px;
  padding-right: 20px;
}

.error {
  padding-top: 15px;
  color: var(--active-color);
}
