.popper {
  opacity: 95%;

  > :global(.MuiTooltip-tooltip) {
    display: inline-flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    background-color: #333333;
    opacity: 95%;
    color: var(--white-color);
    max-width: 250px;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    font-weight: 600;
    line-height: 20px;
    font-size: 12px;
  }
}
