@use 'src/styles/flyout';

.container {
  border: none;
  font: inherit;
  cursor: pointer;
  width: 100%;
  padding: 0 0 0 1rem;
  margin-top: 0.7rem;
  height: 3rem;
  border-radius: 4px;
  line-height: 1em;
  background-color: white;
  color: #10365a;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .address {
    background-color: var(--background-color);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 100%;
    font-size: 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
  }

  &:hover {
    box-shadow: 0 1px 6px #00000080;
  }

  &.selected {
    color: white;
    background-color: #ff4d4d;

    .categoryName {
      background-color: #ff4d4d;
    }
  }
}
