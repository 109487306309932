.container {
  border: none;
  outline: none;
  padding: 0.7rem 0;

  textarea {
    border: none;
    outline: none;
    background-color: var(--white-color);
    padding: var(--form-box-padding);
    display: block;
    width: 100%;
    box-sizing: border-box;
    color: var(--primary-color);
    font-family: var(--default-font-family);
    font-size: var(--font-size-medium);
  }
}

.label {
  display: block;
}

@mixin list {
  margin: 4px 0 0;
  padding: 0;
  list-style: none;
}

.checkboxes {
  @include list;

  li {
    display: flex;
    align-items: center;
  }

  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    $size: 14px;
    $color: #8599ab;

    input[type='checkbox'] {
      --active: #{$color};
      --active-inner: #fff;
      --focus: 2px rgba(39, 94, 254, 0.3);
      --border: #{$color};
      --border-hover: #275efe;
      --background: #fff;
      --disabled: #f6f8ff;
      --disabled-inner: #e1e6f9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: $size;
      width: $size;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition:
        background 0.3s,
        border-color 0.3s,
        box-shadow 0.2s;
      border-radius: 2px;
      top: -1px;
      flex-shrink: 0;

      &:after {
        content: '';
        display: block;
        position: absolute;
        transition:
          transform var(--d-t, 0.3s) var(--d-t-e, ease),
          opacity var(--d-o, 0.2s);
        opacity: var(--o, 0);

        width: 3px;
        height: 7px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 4px;
        top: 0;
        transform: rotate(var(--r, 20deg));
      }

      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        --o: 1;
        --r: 43deg;
      }

      &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;

        &:checked {
          --b: var(--disabled-inner);
          --bc: var(--border);
        }

        & + label {
          cursor: not-allowed;
        }
      }

      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }

      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }

      & + label {
        cursor: pointer;
        margin-left: 8px;
        line-height: 1;
        padding: 4px 0;
      }
    }
  }
}

.radios {
  @include list;
  display: flex;

  li {
    position: relative;
    $size: 30px;

    & + li {
      margin-left: 8px;
    }

    input[type='radio'] {
      position: absolute;
      left: -10000px;

      & + label {
        display: block;
        height: $size;
        min-width: $size;
        text-align: center;
        line-height: 1;
        border-radius: ($size * 0.5);
        --bg: #8599ab;
        background-color: var(--bg);
        color: var(--white-color);
        cursor: pointer;
        padding: 9px 12px 0;
        box-sizing: border-box;
      }

      &:checked + label {
        --bg: var(--primary-color);
      }
    }
  }
}
