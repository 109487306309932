.checkbox {
  display: flex;
  flex-direction: row;

  background: none;
  border: none;
  &:focus,
  &:active {
    outline: 0;
  }

  &.labelAfter {
    flex-direction: row-reverse;
    gap: 10px;
  }

  .label {
    margin-top: 3px;
    margin-right: 10px;
    text-align: left;
    color: var(--primary-color);
    font-size: var(--default-font-size);
  }

  .icon {
    fill: var(--primary-color);
  }

  * {
    cursor: pointer;
  }
}

.fullWidth {
  flex-grow: 1;
}
