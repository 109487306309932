@use 'src/styles/flyout';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 20px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    // grid-row-gap: 10px;
    width: 380px;
}

.tooltip {
    position: relative;
    font-size: 12px;
}

.dayLabel {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dayContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    &.selected {
        border-radius: 4px;
        background: var(--font-light-color, #DAE6ED);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    }
}

.day {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 14px;

    .dayNumber {
        display: inline-block;
        padding-top: 5px;
    }
}

.notInSelectedMonth {
  opacity: 0.5;
}

.futureDay {
  opacity: 0.5;
}

.noSessions {
    background-color: var(--white-color);
}

.allProcessed {
    background-color: #0E8852;
}

.progress {
    position: absolute;
    top: 0;
    left: 0;
}

.backgroundProgress {
    position: absolute;
    top: 0;
    left: 0;
}
