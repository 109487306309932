@use 'src/styles/flyout';
@import 'src/styles/variables';

$dialog-top: 8%;
$dialog-max-height: 84%;

.root {
  :global(.MuiPaper-root) {
    border-radius: 8px;
    top: $dialog-top;
    max-height: $dialog-max-height;
    position: absolute;

    color: var(--primary-color);
  }

  .label,
  label {
    font-weight: 500;
  }

  strong {
    font-weight: 600;
  }

  .successContent {
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .successActions {
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content {
    display: flex;
    gap: 20px;
    flex-direction: column;

    .note {
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: center;

      border-radius: 4px;
      background-color: #ffeaea;
      padding: 10px;

      .noteTitle {
        color: #a41402;
        font-weight: var(--font-weight-bold);
        text-align: center;
      }

      .noteText {
        text-align: center;
      }

      .policyLink {
        color: #3268c5;
      }
    }
  }

  .waiveReasonNote {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .waiveReasonOtherNote {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ccc;
  }

  .cancelReason {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $font-light-color;

    fieldset {
      padding-bottom: 0;
      padding-top: 20px;
    }

    &Note {
      margin-top: 5px;
      margin-bottom: 5px;

      &.label:after {
        content: '*';
      }
    }

    &Display {
      margin-bottom: 20px;

      &.label {
        margin-bottom: 5px;
      }
    }
  }

  .reschedulePreview {
    padding-bottom: 20px;
    border-bottom: 1px solid $font-light-color;

    &.label {
      margin-bottom: 5px;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-left: -5px;
    margin-bottom: 10px;
  }

  .error {
    color: var(--error-color);
  }

  .textAreaError {
    border: 1px solid var(--error-color);
  }

  .lifeTimeStats {
    background-color: #f9f9f9;
    margin: 10px 0;
    padding: 5px 28.5px;
    justify-content: center;

    button {
      font-size: 14px;
      line-height: 20px;
      margin-right: 0;
    }
  }
}

.success {
  :global(.MuiPaper-root) {
    border-radius: 8px;
    top: $dialog-top;
    position: absolute;
    min-width: 452px;
    max-height: $dialog-max-height;
  }

  padding: 40px;

  .content {
    color: var(--primary-color);

    display: flex;
    gap: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    .successTitle {
      font-size: 16px;
      font-weight: var(--font-weight-medium);
    }

    .updated {
      margin-top: -10px;
      margin-bottom: -10px;

      border-radius: 8px;
      border: 1px solid #dae6ed;

      width: 90%;
      overflow: hidden;

      .title {
        background-color: #dae6ed;
        text-align: center;
        padding-top: 8px;
        padding-bottom: 8px;
        font-weight: var(--font-weight-medium);
      }

      ul {
        margin: 0;
        padding: 0 0 10px;
      }

      li {
        list-style: none;
        display: grid;
        grid-template-columns: 20px 1fr;
        grid-column-gap: 10px;

        margin: 15px 20px 0 20px;

        > svg {
          margin-top: -2px;
        }
      }
    }
  }

  .actions {
    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px 43px 20px 43px;
  }
}
