.tabBarContainer {
  $tab-bar-container-width: 80%;
  margin-top: 3rem;
  width: $tab-bar-container-width;
  margin-left: calc((100% - #{$tab-bar-container-width}) / 2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.providerFilter {
  margin: 2rem 2rem 0 2rem;
}
