@use 'src/styles/flyout';

.container {
  display: grid;
  grid-template-rows: repeat(4, max-content) 1fr;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
}

.feedList {
  padding-bottom: 5rem;
}

.banner {
  width: 100%;
  height: 5rem;
  background-color: #f7f9fc;
  right: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 6px #00000080;

  a {
    padding: 0.9rem 2.5rem 0.7rem 2.5rem;
    border-radius: 2rem;
    border: none;
    background-color: #ff4d4d;
    color: white;
    text-decoration: none;

    &:hover {
      box-shadow: 0 1px 6px #00000080;
    }
  }
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}
