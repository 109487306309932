$avatarSize: 2rem;

.container {
  margin: calc(var(--flyout-padding) / 2) var(--flyout-padding);
  overflow: hidden;
  background-color: white;
  padding: 2rem;
  border-radius: 2px;
  position: relative;
}

.bonusImage {
  display: block;
  max-width: 100%;
}

.inlineBonusAmount {
}

.iconCircleList {
  display: flex;
  flex-direction: row;
  width: 90%;
}

.iconCircleSubList {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.iconCircle {
  height: $avatarSize;
  width: $avatarSize;
  border-radius: 50%;
  background-color: #8599ab;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: contain;
  }

  div {
    color: white;
    padding-top: 0.58rem;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.contentContainer {
  width: 100%;
  color: #8599ab;
}

.postLink {
  position: absolute;
  top: 1rem;
  right: 1rem;
  text-decoration: none;
  padding: 0.75rem;
  color: #10365a;

  background-color: var(--white-color);
  border-radius: 50%;

  width: 1em;
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &.selected {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.giverName {
  color: black;
  margin-right: 0.5rem;
}

.inlineBonusAmount {
  border: 1px #3f78af solid;
  border-radius: 4px;
  font-size: 0.8em;
  padding: 0.3em 0.5em 0.1em 0.5em;
  color: #3f78af;
  margin-left: 0.3em;
  margin-right: 0.3em;
}

.userLink {
  text-decoration: none;
  color: #3f78af;
}

.hashtag {
  text-decoration: none;
  color: #10365a;
}

.bonusImageWrapper {
  .bonusImage {
    width: 60%;
    max-width: 60%;
    margin-top: 0.5rem;
  }
}
