.bg-all {
  background-color: var(--date-filter-color-all);
}

.bg-pastDue {
  background-color: var(--date-filter-color-past);
}

.bg-dueToday {
  background-color: var(--date-filter-color-today);
}

.bg-upcoming {
  background-color: var(--date-filter-color-future);
}
