.pdfContainer {
  --border-radius: 3px;
  border: none;
  font: inherit;
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  width: calc(100% - var(--flyout-padding) * 2);
  padding: 0 0 0 10px;
  margin: 0.7rem var(--flyout-padding) 0;
  height: 40px;
  border-radius: var(--border-radius);
  line-height: 1;
  background-color: white;
  color: var(--primary-color);
  display: grid;
  grid-template-columns: repeat(3, max-content) 1fr max-content;
  grid-template-areas: 'checkbox name icon . category';
  gap: 10px;
  align-items: center;
  justify-content: start;
  text-align: start;

  .categoryName {
    grid-area: category;
    background-color: var(--background-color);
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    height: 100%;
    width: 5rem;
    font-size: 12px;
    color: white;
    display: block;
    line-height: 40px;
    text-align: center;
    font-weight: 300;
  }

  .name {
    grid-area: name;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
  }

  .checkbox {
    grid-area: checkbox;
  }

  .icon {
    grid-area: icon;
  }

  &:hover {
    box-shadow: 0 1px 6px #00000080;
  }

  &.selected {
    color: white;
    background-color: #ff4d4d;

    .categoryName {
      background-color: #ff4d4d;
    }
  }
}

.popper {
  .tooltip {
    font-size: 12px;
    line-height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    border-radius: 15px;
    box-sizing: border-box;
    background: #808080;
  }
}
