.container {
  background: var(--white-color);
  padding: 0 12px;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  order: 3;
  margin-bottom: 10px;

  &.linked {
    order: 9;
    outline: none;
    appearance: none;
    border: none;
    text-align: left;
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.content {
  padding: 12px 0;

  h3 {
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    color: var(--primary-color);
    margin: 0 0 7px;
    line-height: 15px;
  }

  p {
    color: var(--staff-color-text);
    font-size: 12px;
    font-weight: var(--font-weight-medium);
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  em {
    display: flex;
    color: var(--staff-color-text);
    align-items: center;
    font-style: normal;
    font-size: 12px;
    font-weight: var(--font-weight-medium);

    &.linkedToCurrentInvoice {
      color: var(--success-color);
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  align-self: stretch;
  position: relative;

  small {
    position: absolute;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--success-color);
    display: block;
    white-space: nowrap;
    font-weight: var(--font-weight-medium);
  }
}

.button {
  align-self: center;
}

.infoIcon {
  position: relative;
  top: -2px;
  margin-left: 5px;
  color: var(--light-info-color);

  h3 & {
    top: 5px;
  }
}
