div.modalContent {
  position: relative;
  height: 750px;
  min-width: 450px;

  &.splitModal {
    background-color: transparent;
    padding: 0;
    min-width: 800px;
    box-shadow: none;
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;

  &.splitModal {
    top: 35px;
    right: 35px;
  }
}
