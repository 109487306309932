.container {
  padding: 4px;
  align-items: baseline;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--provider-payment-color-extra-shift-day, #376ea6);
  color: #fff;
  font-weight: 700;
  line-height: 18px;
  display: inline-flex;
}
