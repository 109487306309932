@use 'src/styles/flyout';

.container {
  overflow-y: scroll;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}

.filterText {
  margin: var(--flyout-padding);
  margin-top: calc(var(--flyout-padding) * 1.5);
  margin-bottom: 0;
}

.list {
  margin: var(--flyout-padding);
  margin-bottom: 3px;
  overflow-y: scroll;
}

.listItem {
  position: relative;
  margin: 8px 8px;
  padding: 12px 20px 8px 20px;
  border: none;
  border-radius: 3px;
  background-color: var(--white-color);
  vertical-align: middle;

  &:hover {
    box-shadow: 0 1px 6px #00000080;
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: 0;
  }

  &.active {
    color: var(--white-color);
    background-color: var(--active-color);
  }
}
