.container {
  height: 35px;
  border-radius: 17px !important;
  border: 1px solid hsl(0, 0%, 80%);
  display: grid;
  grid-template-columns: 30px 1fr;

  button {
    border: none;
    background: transparent;

    &:focus,
    &:active {
      outline: 0;
    }
  }

  .icon {
    margin-top: 6px;
    margin-left: 8px;
  }

  .filterText {
    display: inline-block;
    margin-top: 1px;
    border: none;
    background: transparent;

    &:focus,
    &:active {
      outline: 0;
    }
  }
}
