.container {
  .title,
  .content {
    color: var(--primary-color);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .titleIcon {
    position: relative;
    top: 4px;
    margin-right: 5px;
    color: var(--error-color);
  }

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: var(--primary-color);
  }

  .generalNote {
    border-radius: 4px;
    border: 1px solid #f7b500;
    background: #fffbf2;
    padding: 10px;
    color: var(--primary-color);
    text-align: center;
  }

  .periodNote {
  }

  .outsidePeriodConfirmation {
    margin: 30px 0;
  }

  .creditCardsHeader {
    padding: 0;
    margin: 0;
  }

  .creditCards {
    padding: 0;
    margin: 0;
  }

  hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid #dae6ed;
    margin: 0;
  }

  .cancellationReasonNote {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ccc;
  }

  .confirmationNote {
    padding: 10px;
    background-color: #ffeaea;
    text-align: center;
  }

  .error {
    color: var(--error-color);
  }

  .outsidePeriodTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    text-transform: uppercase;
    font-weight: 900;

    margin-bottom: 15px;

    .raisedHand {
      font-size: 24px;

      &:after {
        content: '\0270B';
      }
    }
  }

  .button {
    border-radius: 17px;
    padding: 6px 20px;
    margin: 15px;
    min-width: 150px;

    &.nextButton {
      background-color: var(--secondary-color);
    }
  }
}
