.container {
  display: grid;
}

@for $i from 2 through 4 {
  .columns#{$i} {
    grid-template-columns: repeat($i, 1fr);
  }
}

.tab {
  background-color: unset;
  border: none;
  border-bottom: solid 3px #dce3ea;
  height: 45px;
  color: var(--primary-color);
  cursor: pointer;

  &.activeTab {
    border-bottom-color: var(--primary-color);
  }
}

.tab:focus,
.tab:active {
  outline: none;
}
