.container {
  display: grid;
  grid-template-columns: max-content 2fr 1fr 140px max-content max-content max-content;
  grid-column-gap: 10px;
  padding: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  background-color: var(--vs-ext-flyout-bg-color);
}

.checkboxColumn {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.item {
  align-content: center;
}

.runReportButton {
  background-color: var(--secondary-color);
  color: var(--white-color);
}
