@use 'src/styles/flyout';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.appointments {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}

.daySelect {
  margin-top: calc(var(--flyout-padding) / 2);
}
