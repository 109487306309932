.container {
  margin-top: 24px;
}

.card {
  display: grid;
  grid-template-columns: 32px 1fr 75px 50px;
  grid-column-gap: 16px;
  background: var(--white-color);
  padding: 0 12px;
  border-radius: 4px;
  height: 55px;
  align-items: center;

  &Icon {
    max-width: 32px;
    max-height: 32px;
    width: 100%;
    display: block;
  }
}

.markAsDefault {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 32px;
}

.button {
  color: var(--white-color);
}

.submit {
  background-color: var(--primary-color);
}

.sendLink {
  background-color: var(--secondary-color);
  margin-left: 16px;
}
