.container {
  margin: 25px;

  .block {
    border: 1px solid var(--warning-color);
    border-radius: 10px;
    padding: 5px 20px 10px;
    text-align: center;
    font-size: 14px;
    background-color: var(--shift-bg-color-not-responded, #fffbf2);

    h1 {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .loadingWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }

  .questions {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .question {
      .questionLabel {
        color: var(--primary-color);
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 5px;
      }

      .questionInput {
        border-radius: 8px;
        background-color: var(--white-color);

        > textarea {
          margin-right: 60px;
        }

        &.error fieldset {
          border: 1px solid var(--error-color);
        }
      }

      .maxCharacters {
        position: absolute;
        top: 45px;
        right: 15px;
      }
    }
  }

  .saved {
    text-align: right;
  }

  .errorText {
    color: var(--error-color);
  }
}

.actionButtonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;

  .actionButtons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    .cancel {
      color: var(--secondary-color);
    }

    .save {
      border-color: var(--secondary-color);
      color: var(--secondary-color);
      border-radius: 20px;
      text-transform: capitalize;

      &:hover {
        opacity: 0.7;
      }
    }

    .submit {
      background-color: var(--secondary-color);
      border-radius: 20px;
      text-transform: capitalize;

      &.disabled {
        background-color: #0000001f;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
