.container {
  border-radius: 4px;
  background: #ebeff3;
  padding: 20px;
  margin: var(--flyout-padding);

  .title {
    font-weight: 600;
    margin-top: 24px;
  }
}
