.container {
  position: absolute;
  width: 21px;
  height: 21px;
  flex-shrink: 0;
  background-color: var(--secondary-color);
  border-radius: 100px;
  top: 0.5px;
  left: 0.5px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .number {
    color: var(--white-color);
    font-size: 12px;
    font-weight: 700;
    line-height: 125%;
    margin: 1px;
    padding-right: 1px;
  }
}
