@use 'sass:color';
@import 'src/styles/variables';

.container {
  display: grid;
  --size: 30px;
  grid-template-columns: repeat(7, var(--size));
  row-gap: 12px;
  column-gap: 25px;
  padding: 20px 0 0;
  text-align: center;
  place-items: center;
  justify-content: center;
}

.day {
  background-color: $date-filter-color-future;
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  line-height: 1;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &[role='button'] {
    cursor: pointer;
  }

  &.empty {
    background: none;
  }

  &__heading {
    color: var(--primary-color);
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
  }

  &.past {
    background-color: color.adjust($date-filter-color-future, $alpha: -0.5);

    &.noShifts {
      background-color: color.adjust($pdf-preview-bg-color, $alpha: -0.55);
    }
  }

  &.noShifts {
    background-color: $pdf-preview-bg-color;
  }

  &.selected:before {
    --selected-size: 40px;
    --offset: calc((var(--size) - var(--selected-size)) / 2);
    position: absolute;
    content: '';
    display: block;
    z-index: -1;
    border-radius: 4px;
    background: var(--font-light-color, #dae6ed);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    width: var(--selected-size);
    height: var(--selected-size);
    top: var(--offset);
    left: var(--offset);
  }
}

.popper {
  width: 270px;

  .tooltip {
    box-sizing: content-box;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #fff !important; // @todo rm !important
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15);
    text-align: left;
    padding: 30px 20px 26px;
    color: var(--primary-color);
    font-size: 16px;
    line-height: 14px;
    margin: 0;

    strong,
    dt {
      font-size: 16px;
      line-height: 14px;
      font-weight: 700;
      display: block;
    }

    strong + dl {
      margin-top: 36px;
    }

    dl {
      display: grid;
      grid-template-columns: 100%;
      grid-row-gap: 8px;
      margin: 0;
      padding: 0;
      color: var(--primary-color);

      dt,
      dd {
        margin: 0;
        padding: 0;
      }

      dd {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        gap: 8px;
        font-size: 14px;
        line-height: 1;

        > span {
          display: block;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.loading {
  grid-column: 1 / -1;
  line-height: 0;
  padding: 7px 0;
}
