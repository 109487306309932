.root {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 10px;

  &.buffer {
    padding: 8px 0;
  }

  .input {
    width: 100%;

    .error {
      border: 1px solid var(--secondary-color);
    }
  }

  .infoIcon {
    font-size: 14px;
    margin-left: 3px;
    margin-bottom: -3px;
    color: #b3cdda;
  }

  .characterCount {
    position: absolute;
    top: 40px;
    right: 45px;

    .invalid {
      color: var(--secondary-color);
    }
  }
}

.iconWrapper {
  margin-top: 37px;
  color: var(--text-block-color);

  &.smallBuffer {
    margin-top: 27px;
  }

  &.valid {
    color: var(--success-color);
  }
}

.errorText {
  margin-top: 5px;
  font-size: 12px;
  color: var(--secondary-color);
}

.attachmentRoot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.attachmentLabelWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  .attachmentInputLabel {
    display: inline-block;
    border: 1px solid var(--active-color);
    padding: 8px 12px 6px;
    border-radius: 30px;
    color: var(--active-color);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &.selected {
      opacity: 0.8;
      cursor: unset;
    }

    .icon {
      transform: rotate(45deg);
      font-size: 20px;
      margin-bottom: -4px;
    }
  }

  .iconWrapper {
    margin-top: 5px;
  }
}

.attachmentInput {
  visibility: hidden;
  height: 0;
}

.imageWrapper {
  position: relative;

  .image {
    width: 160px;
    margin: 30px 0;
  }

  .deleteButton {
    position: absolute;
    top: 15px;
    right: -15px;
    background: var(--active-color);
    color: var(--white-color);
    padding: 8px;

    &:hover {
      background-color: var(--active-color);
      opacity: 0.8;
    }

    > svg {
      font-size: 15px;
    }
  }
}
