@use 'src/styles/flyout';

.container {
  ul {
    padding: 0;

    li {
      padding: 10px 20px;
      list-style: none;
      margin-bottom: 20px;
      border-radius: 3px;
      background: #fff;

      a {
        color: #376ea6;

        svg {
          position: relative;
          margin-left: 5px;
          top: 5px;
        }
      }
    }
  }
}

.noClient {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #fdf2f3;
  padding: 20px;
}
