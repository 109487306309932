$primary-color: #103559;
$primary-old-color: #10365a;
$secondary-color: #f05152;
$secondary-light-color: #f4d7c6;
$light-color: #f7f1eb;
$beige-color: #f8f1eb;
$success-color: #48d2a0;
$white-color: #fff;
$dark-green-color: #0b7340;
$highlight-yellow-color: #fff5c8;
$black-color: #000;
$error-color: #f54b5e;
$error-color-alt: #d0021b;
$warning-color: #f8c51c;
$warning-color-alt: #f7b500;
$light-info-color: #8599ab;
$text-block-color: #dfebf5;
$status-color: #8fa0b1;
$active-color: #ff4d4d;
$warning-bg-color: #ffeded;

$font-dark-color: $primary-color;
$font-grey-color: #b3cdda;
$font-light-color: #dae6ed;

$pdf-preview-bg-color: #525659;

// fonts
$font-family: 'Sailec', 'Helvetica', 'Arial', sans-serif;

// font sizes
$font-size-extra-small: 10px;
$font-size-small: 12px;
$font-size-medium: 14px;
$font-size-large: 16px;

// font weights
$font-weight-hairline: 100;
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-black: 800;

$navigation-button-icon-size: 24px;
$navigation-button-icon-size-large: 36px;
$navigation-button-color: #ffffff;
$navigation-button-active-bg-color: rgba(255, 255, 255, 0.2);

$flyout-padding: 21px;

$date-filter-color-all: $primary-color;
$date-filter-color-past: $active-color;
$date-filter-color-today: $warning-color-alt;
$date-filter-color-future: #3f78af;

$appointment-log-filter-color-all: $primary-color;
$appointment-log-filter-color-cancels: $active-color;
$appointment-log-filter-color-reschedules: $warning-color-alt;
$appointment-log-filter-color-no-shows: #3f78af;
$appointment-log-filter-color-blockoff: #8599ab;

$shift-bg-color-not-responded: #fffbf2;
$shift-bg-color-confirmed: $white-color;
$shift-bg-color-negative: #fdf2f3;

$staff-color-text: #8599ab;
$staff-color-manager: $primary-color;
$staff-bgcolor-manager: #c6c8f6;
$staff-color-doctor: #ffffff;
$staff-bgcolor-doctor: #10365a;
$staff-color-training-doctor: #ffffff;
$staff-bgcolor-training-doctor: #5f128e;
$staff-color-nurse: #ffffff;
$staff-bgcolor-nurse: #4d77ab;
$staff-color-assistant: #ffffff;
$staff-bgcolor-assistant: #7a8ea1;
$staff-color-carecoordinator: $primary-color;
$staff-bgcolor-carecoordinator: #efd8c8;
$staff-color-overstaffed: #f7b500;
$staff-color-understaffed: #ff4d4d;
$staff-color-no-role: #7a8ea1;

$provider-payment-color-working-day: #1a3657;
$provider-payment-color-pto-day: #dce1e5;
$provider-payment-color-extra-shift-day: #376ea6;
$provider-payment-color-critical-extra-shift-day: #376ea6;
$provider-payment-color-goal-reached: #48d2a0;

$show-more-button-color: #3f78af;

$form-box-padding: 16px;

$tooltip-button-bg-color: #f7f9fc;
