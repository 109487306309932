.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: var(--primary-color);

  .closeIcon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--primary-color);

  .datePickers {
    display: flex;
    align-items: flex-end;
    gap: 10px;

    .picker {
      input {
        padding: 10px 15px;
      }
    }

    .to {
      padding-bottom: 10px;
    }

    .disabled {
      color: #10365a80;
    }
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding-bottom: 2px;
  }

  .error {
    color: var(--active-color);
    padding-top: 4px;
  }

  .signAmount {
    background-color: var(--text-block-color);
    text-align: center;
    padding: 6px;
    border-radius: 4px;
  }

  .actionButtons {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;

    .cancel {
      text-transform: capitalize;
      color: var(--active-color);
      font-weight: 600;
      padding: 6px 0;
      min-width: 0;
    }

    .submit {
      background-color: var(--active-color);
      color: var(--white-color);
      border-radius: 20px;
      padding: 6px 12px;
      text-transform: capitalize;

      &.disabled {
        opacity: 0.6;
      }

      .loading {
        color: var(--white-color);
      }
    }
  }
}
