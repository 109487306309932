@use 'src/styles/flyout';
@import 'src/styles/variables';

.container {
  display: grid;
  grid-template-rows: repeat(6, max-content) 1fr;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
}

.title {
  font-size: var(--font-size-large);
}

.flyoutBlock.apptLogItemList {
  overflow-y: scroll;
  margin-top: 24px;
}

.noEvents {
  margin-bottom: 10px;
  margin-left: var(--flyout-padding);
  color: var(--light-info-color);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
}

.warningMessage {
  color: var(--error-color);
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}
