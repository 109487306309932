@use 'src/styles/flyout';

.container {
  position: relative;
  height: calc(100vh - 130px);
  overflow-y: hidden;

  .showInactivePlans {
    margin-top: 20px;
    margin-left: 20px;
  }

  ul {
    padding: 0 20px;
    max-height: calc(100vh - 295px);
    overflow-y: scroll;

    li {
      list-style: none;
      display: flex;
      gap: 10px;
      margin-bottom: 14px;
      border-radius: 8px;
      background: var(--white-color);
      padding: 15px 20px;
      align-items: center;
      cursor: pointer;

      border: 1px solid var(--white-color);

      &.loading {
        background: transparent;
        text-align: center;
        display: flex;
        justify-content: center;
        cursor: default;
        padding: 0;
        &:hover {
          border: 1px solid var(--white-color);
          background: transparent;
          box-shadow: none;
        }
      }

      &:hover,
      &.selected {
        border: 1px solid #ff4d4d;
        background: var(--white-color);
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
      }

      .patientName {
        font-weight: 600;
      }

      .planPeriod {
        flex-grow: 1;
        text-align: right;

        &.expired,
        &.patientInactive {
          color: #616161;
        }

        &.cancelled {
          color: var(--error-color-alt);
        }
      }

      &.noClient,
      &.noPlans {
        cursor: auto;
        background-color: #fdf2f3;
        justify-content: center;

        &:hover {
          border: 1px solid var(--white-color);
          box-shadow: none;
        }
      }
    }
  }
}
