@use 'src/styles/flyout';

.container {
  color: var(--primary-color);

  .urls {
    display: flex;
    gap: 1rem;
    padding: 0;
    margin: 10px 0 0 0;

    li {
      list-style: none;
    }
  }

  .button {
    color: var(--white-color);
  }

  .signUp {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px 50px;

    background-color: var(--secondary-color);
  }

  .tabContent {
    position: relative;

    height: calc(100vh - 130px);
    overflow-y: scroll;
  }
}
