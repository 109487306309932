@use 'src/styles/flyout';

.container {
  display: grid;
  grid-template-rows: repeat(4, max-content) 1fr;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
}

.taskList {
  height: 100%;
  overflow-y: scroll;
  margin-top: var(--flyout-padding);
}

.dateFilterTypeTitle {
  margin-top: var(--flyout-padding);
  margin-left: var(--flyout-padding);
  margin-bottom: 10px;
}

.noTasks {
  margin-bottom: 10px;
  margin-left: var(--flyout-padding);
  color: var(--light-info-color);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}
