.containerWrapper {
  position: relative;
  align-content: center;
}

.container {
  width: 100%;
  min-width: 200px;
  height: 35px;
  border-radius: 17px;
  border: 1px solid hsl(0, 0%, 80%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.picker {
    height: 38px;
    min-width: 180px;
    background-color: var(--white-color);
    padding: 0 12px;
  }

  button {
    border: none;
    background: transparent;

    &:focus,
    &:active {
      outline: 0;
    }
  }

  .buttonBack {
    margin-top: 2px;
    margin-left: 3px;
  }

  .buttonForward {
    margin-top: 2px;
    margin-right: 3px;

    svg {
      transform: rotate(180deg);
    }
  }

  .date {
    margin-top: 1px;
    display: inline-block;
    text-align: center;
  }
}

.calendar {
  border-radius: 5px;
  position: absolute;
  top: 46px;
  left: 0;
  z-index: 99;
  background-color: var(--white-color);
  box-shadow: 0 4px 8px 0 #00000040;
}
