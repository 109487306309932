div.modalContent {
  position: relative;
  max-height: 500px;
  min-width: 450px;
  padding: 10px;
}

.modalTitle {
  padding: 10px;
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 500;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

th.cell {
  font-size: 10px;
  font-weight: 700;
}

td.cell {
  font-size: 12px;
}

th.cell,
td.cell {
  padding: 10px;
  color: var(--primary-color);
}

.feeChargedIcon {
  color: #0b7340;
}

.feeWaivedIcon {
  color: #ff4d4d;
}

.firstFreeCancelRescheduleIcon {
  position: relative;
  top: -2px;
  margin-right: 5px;
}

div.feeInfoTooltip {
  background-color: #333333f2;
}

.feeInfoTooltipText {
  font-size: 11px;
  line-height: 20px;
}

.feeInfoIcon {
  color: #7e8da0;
}
