.root {
  justify-self: start;
  position: relative;
}

.progress {
  position: absolute;
  top: -3px;
  left: -3px;
  z-index: 1;
}
