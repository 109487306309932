.canOverflow {
  overflow-y: visible !important;
}

div.paper {
  max-width: 70%;
  min-width: 400px;
}

.status {
  min-height: 180px;
  display: grid;
  place-items: center;

  svg {
    font-size: 60px;
  }

  text-align: center;

  p,
  strong {
    color: var(--primary-color);
  }

  &.error {
    svg {
      color: var(--error-color);
    }

    strong {
      display: block;
      font-size: 1.4rem;
      font-weight: var(--font-weight-regular);
    }
  }

  &.success {
    svg {
      color: var(--success-color);
    }
  }
}

.loading {
  text-align: center;
}
