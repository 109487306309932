@use 'src/styles/flyout';

.container {
  display: grid;
  grid-template-columns: 1fr var(--vs-ext-flyout-default-width);
  height: 100%;
  width: 100%;
}

.flyout {
  display: grid;
  grid-template-rows: repeat(3, max-content) 1fr max-content;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
  background-color: var(--vs-ext-flyout-bg-color);
  position: relative;
  overflow: hidden;
}

.filterInput {
  font-size: 0.9rem;
}

.previewContainer {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.emailFrame {
  width: 70%;
  height: 80%;
}

.preview {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
}

.searchInput {
  background-color: white;
  color: #10365a;

  input {
    color: inherit;
  }

  ::placeholder {
    color: inherit;
    opacity: 0.85;
  }
}

.hospitalContainer {
  overflow: auto;
}
