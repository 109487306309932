.container {
  color: var(--primary-color);
  background: #f1f6f8;
  font-size: 0.75rem;
  line-height: 0.75rem;
  padding: 1.25rem 1rem 1rem 1rem;
  margin-bottom: 1rem;

  .title {
    text-transform: uppercase;
    font-weight: 700;
  }

  .subtitle {
    font-style: italic;
    text-transform: lowercase;
    font-weight: 500;
  }
}

.breakSection {
  @extend .container;
  break-after: page;
}

.childrenContainer {
  display: flex;
  gap: 1rem;
  flex-direction: row;
}
