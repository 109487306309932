div.modalContent {
  position: relative;
  min-height: 450px;
  max-height: 600px;
  min-width: 500px;
  max-width: 650px;

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px;

    .image {
      max-width: 50%;
      border-radius: 4px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    box-shadow: 0px -5px 8px 0px rgba(0, 0, 0, 0.1);

    .closeButton {
      color: var(--primary-color, #103559);
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      text-transform: none;
    }

    .downloadButton {
      background-color: var(--active-color);
      color: var(--white-color);
      padding: 8px 12px 10px 12px;
      text-transform: none;
      border-radius: 200px;

      .downloadText {
        display: flex;
        gap: 10px;
        align-items: baseline;
        color: var(--white-color);
        font-size: 14px;
        font-weight: 500;
        line-height: 100%;
        text-decoration: none;
      }
    }
  }
}
