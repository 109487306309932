.flyout {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  &Block {
    margin: calc(var(--flyout-padding) / 2) var(--flyout-padding);

    &.first {
      // margin-top on the first element creates a vertical scroll bar
      padding-top: var(--flyout-padding);
      margin-top: 0;
    }

    &.noMargin {
      margin: 0;
    }

    &.noHorizontal {
      margin-left: 0;
      margin-right: 0;
    }

    &.noVertical {
      margin-top: 0;
      margin-bottom: 0;
    }

    &.padding {
      padding: var(--flyout-padding);
    }
  }

  &Title {
    font-size: var(--font-size-large);
  }

  &SubTitle {
    margin-top: 3px;
    font-size: var(--font-size-small);
    color: var(--light-info-color);
    font-weight: var(--font-weight-regular);
  }

  &Content {
    flex-grow: 1;
    flex-shrink: 0;
  }
}
