@import './variables';

/**
 * @license
 * MyFonts Webfont Build ID 3718361, 2019-02-11T14:18:54-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Sailec-Bold by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/bold/
 *
 * Webfont: Sailec-BlackItalic by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/black-italic/
 *
 * Webfont: Sailec-HairlineItalic by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/hairline-italic/
 *
 * Webfont: Sailec-Hairline by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/hairline/
 *
 * Webfont: Sailec-BoldItalic by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/bold-italic/
 *
 * Webfont: Sailec-Black by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/black/
 *
 * Webfont: Sailec-Light by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/light/
 *
 * Webfont: Sailec-LightItalic by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/light-italic/
 *
 * Webfont: Sailec-Medium by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/medium/
 *
 * Webfont: Sailec-MediumItalic by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/medium-italic/
 *
 * Webfont: Sailec-Regular by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/regular/
 *
 * Webfont: Sailec-RegularItalic by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/regular-italic/
 *
 * Webfont: Sailec-Thin by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/thin/
 *
 * Webfont: Sailec-ThinItalic by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/sailec/thin-italic/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3718361
 * Licensed pageviews: 20,000
 * Webfonts copyright: Copyright &#x00A9; 2014 by Type Dynamic. All rights reserved.
 *
 * © 2019 MyFonts Inc
*/

@font-face {
  font-family: 'Sailec'; // Hairline
  font-display: swap;
  src: url('../fonts/38BCD9_3_0.eot');
  src:
    url('../fonts/38BCD9_3_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_3_0.woff2') format('woff2'),
    url('../fonts/38BCD9_3_0.woff') format('woff'),
    url('../fonts/38BCD9_3_0.ttf') format('truetype');
  font-weight: $font-weight-hairline;
  font-style: normal;
}

@font-face {
  font-family: 'Sailec'; // HairlineItalic
  font-display: swap;
  src: url('../fonts/38BCD9_2_0.eot');
  src:
    url('../fonts/38BCD9_2_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_2_0.woff2') format('woff2'),
    url('../fonts/38BCD9_2_0.woff') format('woff'),
    url('../fonts/38BCD9_2_0.ttf') format('truetype');
  font-weight: $font-weight-hairline;
  font-style: italic;
}

@font-face {
  font-family: 'Sailec'; // Thin
  font-display: swap;
  src: url('../fonts/38BCD9_C_0.eot');
  src:
    url('../fonts/38BCD9_C_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_C_0.woff2') format('woff2'),
    url('../fonts/38BCD9_C_0.woff') format('woff'),
    url('../fonts/38BCD9_C_0.ttf') format('truetype');
  font-weight: $font-weight-thin;
  font-style: normal;
}

@font-face {
  font-family: 'Sailec'; // ThinItalic
  font-display: swap;
  src: url('../fonts/38BCD9_D_0.eot');
  src:
    url('../fonts/38BCD9_D_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_D_0.woff2') format('woff2'),
    url('../fonts/38BCD9_D_0.woff') format('woff'),
    url('../fonts/38BCD9_D_0.ttf') format('truetype');
  font-weight: $font-weight-thin;
  font-style: italic;
}

@font-face {
  font-family: 'Sailec'; // Light
  font-display: swap;
  src: url('../fonts/38BCD9_6_0.eot');
  src:
    url('../fonts/38BCD9_6_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_6_0.woff2') format('woff2'),
    url('../fonts/38BCD9_6_0.woff') format('woff'),
    url('../fonts/38BCD9_6_0.ttf') format('truetype');
  font-weight: $font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: 'Sailec'; // LightItalic
  font-display: swap;
  src: url('../fonts/38BCD9_7_0.eot');
  src:
    url('../fonts/38BCD9_7_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_7_0.woff2') format('woff2'),
    url('../fonts/38BCD9_7_0.woff') format('woff'),
    url('../fonts/38BCD9_7_0.ttf') format('truetype');
  font-weight: $font-weight-light;
  font-style: italic;
}

@font-face {
  font-family: 'Sailec'; // Regular
  font-display: swap;
  src: url('../fonts/38BCD9_A_0.eot');
  src:
    url('../fonts/38BCD9_A_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_A_0.woff2') format('woff2'),
    url('../fonts/38BCD9_A_0.woff') format('woff'),
    url('../fonts/38BCD9_A_0.ttf') format('truetype');
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: 'Sailec'; // RegularItalic
  font-display: swap;
  src: url('../fonts/38BCD9_B_0.eot');
  src:
    url('../fonts/38BCD9_B_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_B_0.woff2') format('woff2'),
    url('../fonts/38BCD9_B_0.woff') format('woff'),
    url('../fonts/38BCD9_B_0.ttf') format('truetype');
  font-weight: $font-weight-regular;
  font-style: italic;
}

@font-face {
  font-family: 'Sailec'; // Medium
  font-display: swap;
  src: url('../fonts/38BCD9_8_0.eot');
  src:
    url('../fonts/38BCD9_8_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_8_0.woff2') format('woff2'),
    url('../fonts/38BCD9_8_0.woff') format('woff'),
    url('../fonts/38BCD9_8_0.ttf') format('truetype');
  font-weight: $font-weight-medium;
  font-style: normal;
}

@font-face {
  font-family: 'Sailec'; // MediumItalic
  font-display: swap;
  src: url('../fonts/38BCD9_9_0.eot');
  src:
    url('../fonts/38BCD9_9_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_9_0.woff2') format('woff2'),
    url('../fonts/38BCD9_9_0.woff') format('woff'),
    url('../fonts/38BCD9_9_0.ttf') format('truetype');
  font-weight: $font-weight-medium;
  font-style: italic;
}

@font-face {
  font-family: 'Sailec'; // Bold
  font-display: swap;
  src: url('../fonts/38BCD9_0_0.eot');
  src:
    url('../fonts/38BCD9_0_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_0_0.woff2') format('woff2'),
    url('../fonts/38BCD9_0_0.woff') format('woff'),
    url('../fonts/38BCD9_0_0.ttf') format('truetype');
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: 'Sailec'; // BoldItalic
  font-display: swap;
  src: url('../fonts/38BCD9_4_0.eot');
  src:
    url('../fonts/38BCD9_4_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_4_0.woff2') format('woff2'),
    url('../fonts/38BCD9_4_0.woff') format('woff'),
    url('../fonts/38BCD9_4_0.ttf') format('truetype');
  font-weight: $font-weight-bold;
  font-style: italic;
}

@font-face {
  font-family: 'Sailec'; // Black
  font-display: swap;
  src: url('../fonts/38BCD9_5_0.eot');
  src:
    url('../fonts/38BCD9_5_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_5_0.woff2') format('woff2'),
    url('../fonts/38BCD9_5_0.woff') format('woff'),
    url('../fonts/38BCD9_5_0.ttf') format('truetype');
  font-weight: $font-weight-black;
  font-style: normal;
}

@font-face {
  font-family: 'Sailec'; // BlackItalic
  font-display: swap;
  src: url('../fonts/38BCD9_1_0.eot');
  src:
    url('../fonts/38BCD9_1_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38BCD9_1_0.woff2') format('woff2'),
    url('../fonts/38BCD9_1_0.woff') format('woff'),
    url('../fonts/38BCD9_1_0.ttf') format('truetype');
  font-weight: $font-weight-black;
  font-style: italic;
}
