@use 'src/styles/flyout';

.root {
  padding: 0 var(--flyout-padding) var(--flyout-padding);
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;

  &,
  * {
    box-sizing: border-box;
  }

  .container {
    border: none;
    outline: none;
    padding: 0.25rem 0;

    .error {
      font-weight: var(--font-weight-bold);
      color: var(--error-color);
    }

    .notesInput {
      margin-top: 15px;
      border: none;
      outline: none;
      background-color: var(--white-color);
      padding: var(--form-box-padding);
      display: block;
      width: 100%;
      box-sizing: border-box;
      color: var(--primary-color);
      font-family: var(--default-font-family);
      font-size: var(--font-size-medium);
    }

    .errorText {
      grid-column: 1 / -1;
      color: var(--secondary-color);
      text-align: center;
      top: 20px;
      position: relative;
    }

    .success {
      color: green;
      font-weight: 500;
      font-size: 1.5rem;
      top: 20px;
      position: relative;
      opacity: 1;
    }

    .processing {
      top: 20px;
      left: 50px;
      position: relative;
    }

    .addToQueueButton {
      color: var(--white-color);
      background-color: var(--secondary-color);
      float: right;
      margin-right: 1rem;
      position: relative;
      opacity: 1;

      .buttonHint {
        display: none;
      }

      &:disabled {
        background-color: var(--secondary-color);
        opacity: 0.5;

        &:hover {
          .buttonHint {
            display: block;
            position: absolute;
            color: white;
            font-size: 0.9rem;
            top: -5rem;
            padding: 4px 6px;
            background: grey;
            border-radius: 17px;
          }
        }
      }
    }

    // UL of payment method, new or known
    .cardsRadio {
      margin: 4px 0 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;

      li {
        position: relative;

        input[type='radio'] {
          position: absolute;
          left: -25px;
          top: 10px;

          &:checked {
            --bg: var(--secondary-color);
          }
        }

        // A known credit card
        .card {
          display: grid;
          grid-template-columns: 32px 1fr 75px 50px;
          grid-column-gap: 16px;
          background: var(--white-color);
          padding: 0 12px;
          border-radius: 4px;
          height: 45px;
          align-items: center;

          &Icon {
            max-width: 32px;
            max-height: 32px;
            width: 100%;
            display: block;
          }

          .logo {
            max-width: 37px;
            max-height: 37px;
            width: 100%;
          }

          .info {
            border-radius: 4px;
            display: grid;
            grid-template-columns: repeat(3, max-content) 1fr;
            grid-column-gap: 12px;
            align-items: center;
            font-weight: var(--font-weight-medium);
            line-height: 1;
            padding: 8px 12px;

            p {
              margin: 0;
            }
          }

          .chip {
            &.smallChip {
              border-radius: 3px;
            }
          }
        }

        // A new credit card
        .newCard {
          display: grid;
          grid-template-columns: 3fr 1fr 1fr;
          grid-column-gap: 16px;
          background: var(--white-color);
          padding: 0 12px;
          border-radius: 4px;
          height: 55px;
          align-items: center;

          &Icon {
            max-width: 32px;
            max-height: 32px;
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
}

.fullWidth {
  grid-column: 1 / -1;
}

.halfWidth {
  grid-column: auto / span 2;
}
