.faqModal {
  display: block;
  position: absolute;
  height: 100%;
  width: calc(100% + 50px);
  background: rgba(0, 0, 0, 0.5);
}

.faqModalContent {
  background-color: white;
  padding: 1rem;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85vw;
  max-width: 836px;
  height: 75vh;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 1rem;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.title {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
}

.pills {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.pill {
  padding: 8px 16px;
  cursor: pointer;
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  border-radius: 20px;
  background-color: #f7f9fc;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.pill:hover {
  opacity: 0.8;
  cursor: pointer;
}

.pill.active {
  background-color: #f05152;
  color: white;
}

.faqIframe {
  width: 100%;
  height: calc(100% - 60px);
  border: none;
  margin-top: 10px;
}
