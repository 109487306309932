@use 'src/styles/flyout';

.container {
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.5rem;

    .linkLine {
      display: flex;
      margin-left: 1.5rem;
      align-items: baseline;
      gap: 0.25rem;
    }

    .iframe {
      height: 100%;
      border: none;
    }
  }
}
