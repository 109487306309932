@use 'src/styles/flyout';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: var(--vs-ext-flyout-default-width);
  background-color: var(--vs-ext-flyout-bg-color);
  float: right;
  margin: 0;
  padding-top: 0;
  position: relative;
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}

.title {
  margin: var(--flyout-padding);
  margin-bottom: 0;
  font-size: var(--font-size-large);
}

.finished {
  margin: var(--flyout-padding);
}

.successContainer {
  margin: 1.5rem;
  background-color: white;
}

.actions {
  box-shadow: 0 1px 6px #00000080;
  margin-top: calc(var(--flyout-padding) / 2);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  .close {
    float: right;
    color: var(--white-color);
    background-color: var(--primary-color);
    margin-right: 2rem;
  }
}
