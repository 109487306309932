.loading {
  margin-top: var(--flyout-padding);
  padding-top: var(--flyout-padding);
  display: flex;
  justify-content: center;
}

.container {
  position: relative;
  height: calc(100vh - 130px);

  .content {
    height: calc(100% - 110px);
    padding-bottom: 20px;

    position: relative;
    overflow-y: scroll;
  }

  .paymentMethod {
    border-radius: 4px;
    background: #ebeff3;
    position: relative;
    top: var(--flyout-padding);
    padding: var(--flyout-padding);
    margin: 0 var(--flyout-padding);

    .names {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .paymentActions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 30px;

      .button {
        border-radius: 17px;
        padding: 6px 20px;
        min-width: 150px;
        background-color: var(--secondary-color);
      }
    }
  }

  .autoRenew {
    .title {
      font-weight: 600;
    }

    border-radius: 4px;
    background: #ebeff3;
    position: relative;
    top: var(--flyout-padding);
    padding: var(--flyout-padding);
    margin: var(--flyout-padding);

    .autoRenewSwitch {
    }

    .enabledNote {
      padding: 10px;
      background-color: #fffbf2;
      margin-top: 15px;
    }

    .disabledNote {
      padding: 10px;
      background-color: #e5e5e5;
      margin-top: 15px;
    }

    .details {
      border-radius: 4px;
      background: #fff;
      margin-top: 20px;

      padding: 20px;

      .title {
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
      }

      .labelWithValue {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }

      .discount {
        color: var(--active-color);
      }

      hr {
        width: 100%;
        border: none;
        border-bottom: 1px solid #8a8f99;
        margin: 10px 0 12px 0;
      }

      .total {
        font-weight: 600;
      }
    }
  }
}
