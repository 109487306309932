.root {
  margin: 5px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.hidden {
    display: none;
  }

  li {
    list-style: none;
  }

  .card {
    position: relative;

    display: grid;
    grid-template-columns: 25px 40px 120px max-content;
    grid-column-gap: 10px;

    cursor: pointer;

    height: 49px;

    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #9eaab9;
    background: var(--white-color);

    &.error {
      border: 1px solid var(--error-color);
    }

    > input {
      margin-top: 2px;
    }

    > div {
      margin-top: 17px;
    }

    div:global(.MuiChip-root) {
      margin-top: 12px;
    }

    .brandContainer {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .brand {
      max-width: 37px;
      max-height: 37px;
    }

    &.selected {
      border: 1.5px solid #10365a;
      background-color: #ecf1f7;
    }

    .chip {
      margin-top: 0;
      border-radius: 3px;
    }
  }

  div.catchClick {
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
  }

  .newCard {
    grid-template-columns: 20px 40px 160px 65px 40px max-content;
    grid-column-gap: 5px;
  }
}

.label,
label {
  font-weight: 500;
}

.defaultPaymentMethodNote {
  font-weight: var(--font-weight-regular);
  margin-top: 2px;
  margin-bottom: 5px;
}

.error {
  margin-top: 5px;
  color: var(--error-color);
}
