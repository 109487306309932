.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.reportIframeContainer {
  margin: 50px auto;
  background: white;
  width: calc(800px + 4rem);

  .printButtonContainer {
    width: calc(100% - 2rem);
    padding: 1rem;

    .printButton {
      background: none;
      border: none;
      text-decoration: underline;
      color: #0a66be;
    }

    .close {
      float: right;
    }
  }

  .reportIframe {
    border: none;
    width: 100%;
    height: 70vh;

    body {
      margin: 0;
    }
  }
}
