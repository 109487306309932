@use 'src/styles/flyout';

.root {
  padding-bottom: 30px;
}

.row {
  display: grid;
  grid-template-columns: 90px 1fr 80px 55px 50px;
  grid-column-gap: 10px;
  height: 46px;
  box-sizing: border-box;
  padding: 0 20px;
  margin-bottom: 6px;
  align-items: center;

  > span,
  > strong,
  > h4 {
    box-sizing: border-box;
    display: block;
    font-size: 14px;
    line-height: 1;
    font-weight: var(--font-weight-medium);
  }

  > h4 {
    margin: 0 0 -5px;
    color: var(--light-info-color);
  }

  > strong {
    font-weight: var(--font-weight-bold);
  }
}
