@use 'src/styles/flyout';

.name,
.note {
  font-weight: 700;
}

.name {
  padding-right: 16px;
}

.frequency {
  color: var(--light-info-color);
}

.confirmDialogContent {
  width: 300px;
  top: -20px;
  color: var(--primary-color);
}

.selected {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--light-info-color);
  cursor: pointer;
}
