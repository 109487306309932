.root {
  background-color: var(--tooltip-button-bg-color);
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 30px 42px;
  position: relative;

  &.loading {
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 44px;
  }

  dl {
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 8px;
    margin: 0;
    padding: 0;
    color: var(--primary-color);

    dt,
    dd {
      margin: 0;
      padding: 0;
    }

    dt {
      font-size: 16px;
      line-height: normal;
      font-weight: 700;
    }

    dd {
      font-size: 16px;
      line-height: normal;
      font-weight: 400;

      ul {
        padding-left: 24px;
        margin: 0;
      }
    }
  }

  .empty {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    padding-top: 59px;
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 1rem;
  cursor: pointer;
  border: none;
  background-color: transparent;
}
