.container {
  position: relative;
  background-color: var(--white-color);
  border-radius: 6px;
  padding: 16px 10px;
  margin-top: var(--flyout-padding);
  box-shadow: 0px 4px 8px rgba(16, 54, 90, 0.2);
}

.tooltipContent {
  padding: 5px;
}

.lightText {
  color: var(--light-info-color);
}

.tooltipTitle {
  font-weight: 500;
  color: var(--primary-color);
}

.tooltipValue {
  font-weight: 300;
}

.bonus {
  font-size: 40px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  color: var(--primary-color);
  margin-top: 20px;
}

.infoIcon {
  position: relative;
  top: -5px;
  margin-left: 5px;
  color: rgba(var(--primary-color), 0.8);
}

.title {
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: rgba(var(--primary-color), 0.8);
}

.subTitle {
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  color: rgba(var(--primary-color), 0.8);
  margin-bottom: 20px;
  margin-top: 4px;
}

.download {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.openReport {
  color: var(--white-color);
  background-color: var(--primary-color);
}

.openReportDisabled {
  color: var(--white-color);
  background-color: #d4dce4;
}

.downloadError {
  margin-top: 10px;
  color: var(--error-color);
  text-align: center;
}

.celebrationImage {
  margin-top: var(--flyout-padding);
  border-radius: 6px;
  margin-left: 20%;
  width: 60%;
}
