.label {
  line-height: 20px;
}

.option {
  display: flex;
  gap: 8px;
  align-items: baseline;
  justify-content: flex-start;
}

.select {
  :global(.lookup-select__placeholder) {
    margin-top: 0;
    line-height: 1;
  }
}
