$marginTop: 20px;
$arrowSize: 0.8rem;

.container {
  width: var(--vs-ext-navigation-bar-size);
  height: calc(var(--vs-ext-navigation-bar-size) * 0.9);
  display: block;
  background: transparent;
  border: none;
  text-align: center;
  position: relative;

  &.active {
    background-color: var(--navigation-button-active-bg-color);
  }

  &.active > * {
    opacity: 1;
  }

  &.noOpacity > * {
    opacity: 1;
  }

  &:nth-child(2) {
    margin-top: 8px;
  }

  &.expanded {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--navigation-button-color);
    padding-left: 1.6rem;

    .title {
      margin-left: 1.6rem;
      font-size: 1.2rem;
    }
  }

  &:not(.expanded) {
    .title {
      opacity: 1;
      position: absolute;
      display: none;

      height: 2.5rem;
      width: 8rem;
      top: calc((var(--vs-ext-navigation-bar-size) - 2.5rem) / 2);
      right: calc(
        (
            var(--vs-ext-navigation-bar-size) +
              var(--navigation-button-icon-size) + 1.5rem
          ) / 2
      );

      flex-direction: row-reverse;
      justify-content: end;
      align-items: center;
      background-color: white;
      text-wrap: none;
      color: black;
      border-radius: 5px;
      box-shadow: -3px 3px 14px -4px rgb(0 0 0 / 61%);

      span {
        width: 100%;
        color: var(--primary-color);
        font-size: 1rem;
      }
    }
  }
}

.container > * {
  opacity: 0.5;
}

.container:active,
.container:focus {
  outline: 0;
}

.noPointerEvents {
  pointer-events: none;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
