.container {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.searchPane {
  flex: 1;
  border-right: 1px solid #dae6ed;
  min-width: 275px;
  display: flex;
  flex-direction: column;

  .searchFields {
    padding: 10px;
  }
}

.conversationsList {
  padding: 1rem;
  background-color: var(--white-color);
}

.conversationPane {
  flex: 3;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;

  .faqButton {
    border: none;
    background: var(--warning-color);
    padding: 10px 20px;
    border-radius: 20px;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.sortByLabel {
  font-weight: 500;
  font-size: 12px;
  color: var(--primary-color);

  .selection {
    font-weight: 400;
  }
}
