.root {
  .title {
    text-align: center;
    font-size: 20px;

    .closeButton {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      background: none;
      border: none;
    }
  }

  .content {
    width: 400px;
    min-height: 160px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    color: var(--primary-color);

    .text {
      text-align: center;
    }

    .block {
      background-color: #ebeff3;
      border-radius: 8px;
      padding: 20px;

      display: flex;
      flex-direction: column;
      gap: 10px;

      strong {
        font-weight: 600;
      }

      &.note {
        background-color: #fffbf2;
      }
    }

    .amountLine {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      div.input {
        border-radius: 4px;
        background-color: var(--white-color);

        input {
          padding: 5px 10px 5px 0;
          width: 90px;
        }

        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .error {
      margin-top: 15px;
      color: var(--error-color);
    }

    .success {
      margin-top: 60px;
      background-color: #daf6ec;
      padding: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      svg {
        color: #48d2a0;
        margin-top: -5px;
      }
    }
  }

  .actions {
    padding: 0 20px 25px;
    .submit {
      background-color: var(--secondary-color);
      color: var(--white-color);
      border-radius: 50px;

      &:disabled {
        opacity: 0.7;
      }
    }

    .close {
      background-color: #1876d2;
      color: var(--white-color);
      border-radius: 50px;
    }
  }
}
