.container {
  background-color: var(--tooltip-button-bg-color);
  max-width: calc(100% - 275px);

  .conversationContainer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 15px;
    height: calc(100% - 97px);

    .sinceDateBlurb {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      border-bottom: 1px #b3cdda dashed;
    }
  }
}

.noHistory {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;

  > p {
    font-weight: 900;
  }
}

.infiniteScrollWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;

  > div {
    position: relative;
  }

  .loadingWrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
  }

  .messages {
    display: flex;
    flex-direction: column-reverse;
  }

  .topOfScroll {
    margin: 40px 20px 20px 20px;
    text-align: center;
    font-style: italic;
    font-weight: 500;
    color: var(--light-info-color);
    font-size: 12px;
  }
}

.loadingWrapper {
  margin: auto;
}
