@use 'src/styles/flyout';

.container {
  overflow-y: scroll;
  display: grid;
  grid-template-rows: 2fr 1fr max-content;
}

.loading {
  margin-top: var(--flyout-padding);
  text-align: center;
}

.title {
  font-size: var(--font-size-large);
}

.options {
  margin: var(--flyout-padding);
  overflow-y: scroll;
}

.option {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 50px 1fr;
  cursor: pointer;
}

.optionInput {
  align-self: center;
  margin-top: 0;
  margin-bottom: 2px;
}

.itemsWithTitle {
  background-color: var(--white-color);
  padding: var(--form-box-padding);
}

.note {
  margin: var(--flyout-padding);
  background-color: var(--white-color);
  padding: var(--form-box-padding);

  textarea {
    width: 100%;
    margin-top: 5px;
    height: calc(100% - 24px);
    resize: none;
    border: none;
    font-family: var(--default-font-family);
    font-size: var(--font-size-medium);
    outline: none;
  }
}
