.root {
  margin: 40px 20px;
  padding: 16px;
  height: fit-content;

  &.error {
    background-color: var(--warning-bg-color);
  }

  &.success,
  &.info {
    background-color: var(--text-block-color);
  }
}

.success {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;

  .icon {
    fill: var(--success-color);
  }
}
