.container {
  height: calc(100% - var(--flyout-padding));
  position: relative;
  text-align: center;
  color: var(--primary-color);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black-color);
  opacity: 0.1;
  z-index: -1;
}

.content {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.label {
}

.pin {
  display: inline-block;
  border: none;
  background-color: var(--white-color);
  border-radius: 19px;
  padding: 15px 20px;
  letter-spacing: 20px;
  width: 100px;

  &:focus,
  &:active {
    outline: 0;
  }
}
