.root {
  margin-top: 5px;

  .questionLabel {
    color: var(--primary-color);
  }

  .label {
    margin: 15px 0 5px;
  }

  .tips {
    margin: 0;
    padding-left: 15px;
  }

  .examples {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .example {
      display: flex;
      align-items: center;

      .exampleImage {
        position: relative;

        img {
          width: 94px;
          height: 94px;
          border-radius: 4px;
          object-fit: cover;
        }

        .imageIcon {
          font-size: 40px;
          position: absolute;
          bottom: 0;
          right: -18px;
        }
      }

      &.good {
        svg {
          color: var(--success-color);
        }
      }

      &.bad {
        svg {
          color: var(--secondary-color);
        }
      }

      ul {
        list-style: none;
        padding-left: 20px;
        margin: 0;

        > li {
          display: flex;
          align-items: center;

          > svg {
            margin-bottom: 2px;
            margin-right: 2px;
          }
        }
      }
    }
  }

  .headshotInput {
    .imageWrapper {
      display: flex;
      align-items: center;
      gap: 15px;

      .imagePreview {
        width: 94px;
        height: 94px;
        border-radius: 4px;
        object-fit: cover;
      }

      .fileInput {
        display: none;
      }

      .uploadButton {
        border-color: var(--secondary-color);
        color: var(--secondary-color);
        border-radius: 40px;
        margin-bottom: 15px;
        text-transform: capitalize;
        padding: 8px 12px;

        > svg {
          transform: rotate(45deg);
        }
      }
    }
  }
}
