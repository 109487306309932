@use 'src/styles/flyout';

.container {
  display: grid;
  grid-template-columns: 1fr var(--vs-ext-flyout-default-width);
  height: 100%;
  width: 100%;
}

.previewContainer {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
}

.previewBackground {
  position: relative;
  width: 80%;
  height: calc(100% - 200px);
  margin-top: 100px;
  margin-left: 10%;
  background-color: var(--pdf-preview-bg-color);
}

.preview {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
}

.previewLoading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewLoadingIcon {
  color: var(--light-info-color);
}

.noSurgeryFormSelected {
  color: var(--white-color);
  font-size: var(--font-size-large);
  text-align: center;
  opacity: 0.5;
  top: 50%;
  position: relative;
}

.flyout {
  background-color: var(--vs-ext-flyout-bg-color);
  display: grid;
  grid-template-rows: max-content max-content 1fr max-content;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.actions {
  box-shadow: 0 1px 6px #00000080;
  margin-top: calc(var(--flyout-padding) / 2);
  display: grid;
  grid-template-columns: max-content 1fr max-content;

  .back {
    color: var(--primary-color);
    background-color: var(--white-color);
  }

  .print {
    color: var(--white-color);
    background-color: var(--light-info-color);
  }

  .next {
    color: var(--white-color);
    background-color: var(--secondary-color);
  }

  .close {
    color: var(--white-color);
    background-color: var(--primary-color);
  }
}
