@use 'src/styles/flyout';

.container {
  background-color: #e9edf1;
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;

  h5 {
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    color: var(--primary-color);
    margin: 0 0 5px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  .subCopy {
    margin-bottom: 30px;
  }
}

.updateInfo {
  border-radius: 4px;
  background: #f7f9fc;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  margin-top: 30px;
  padding: 8px;

  p {
    font-size: 13px;
    line-height: 1.5;
    font-weight: 700;
    text-align: center;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
  }
}

.conflicts {
  display: grid;
  padding: 8px;
  margin-top: 18px;
  border-radius: 4px;
  background: var(--bg-color-negative, #fdf2f3);
  grid-template-columns: 1fr;
  grid-row-gap: 10px;

  dd,
  ul,
  dl {
    margin: 0;
  }

  ul {
    padding-left: 24px;
  }

  p,
  dt {
    font-size: 13px;
    line-height: 1.5;
    font-weight: 700;
  }
}

.form {
  padding-bottom: 30px;
}

.actions {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 60px;
  justify-content: flex-end;
  margin-top: 30px;
  grid-column-gap: 19px;
  align-items: center;

  button[type='submit'] {
    grid-column-start: 2;
    border-radius: 20px;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    padding: 8px 20px;
    background-color: var(--secondary-color);
    transition: opacity 0.3s ease-in-out;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    color: #fff;
    cursor: pointer;

    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  .success {
    grid-column: 1 / span 1;
    margin: 0;
    height: 60px;
    box-sizing: border-box;
    justify-content: center;
    gap: 10px;
    align-items: center;
    line-height: 24px;
    border-radius: 4px;
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition:
      opacity 0.4s linear,
      visibility 0s;

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    &Icon {
      width: 24px;
      height: 24px;
    }
  }
}
