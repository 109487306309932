@use 'src/styles/flyout';

.container {
  display: grid;
  grid-template-columns: 1fr var(--vs-ext-flyout-default-width);
  height: 100%;
  width: 100%;
}

.flyout {
  display: grid;
  grid-template-rows: repeat(4, max-content) 1fr max-content;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
  background-color: var(--vs-ext-flyout-bg-color);
  position: relative;
  overflow: hidden;
}

.previewContainer {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
}

.previewBackground {
  position: relative;
  width: 80%;
  height: calc(100% - 200px);
  margin-top: 100px;
  margin-left: 10%;
  background-color: var(--pdf-preview-bg-color);
}

.preview {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
}

.previewLoading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewLoadingIcon {
  color: var(--light-info-color);
}

.previewLoadingIcon {
  color: var(--light-info-color);
}

.noPdfSelected {
  color: var(--white-color);
  font-size: var(--font-size-large);
  text-align: center;
  opacity: 0.5;
  top: 50%;
  position: relative;
}

.title {
  font-size: var(--font-size-large);
}

.noPatientContainer {
  margin: calc(var(--flyout-padding) / 2) var(--flyout-padding);
  overflow: hidden;
  background-color: white;
  padding: 2rem;
  border-radius: 2px;
  position: relative;

  .errorTextContainer {
    margin: 0.75rem 0;
    padding: 0.7rem;
    background-color: rgba(255, 77, 77, 0.1);
  }
}

.searchInput {
  background-color: white;
  color: #10365a;

  input {
    color: inherit;
  }

  ::placeholder {
    color: inherit;
    opacity: 0.85;
  }
}

.filterInput {
  font-size: 0.9rem;
}

.actions {
  box-shadow: 0 1px 6px #00000080;
  margin-top: calc(var(--flyout-padding) / 2);

  .button {
    color: var(--white-color);
    background-color: var(--primary-color);
    float: right;
    margin-right: 1rem;
    position: relative;
    opacity: 1;

    .buttonHint {
      display: none;
    }

    &:disabled {
      background-color: rgba(16, 53, 89, 0.2);

      &:hover {
        .buttonHint {
          display: block;
          position: absolute;
          color: white;
          font-size: 0.9rem;
          top: -5rem;
          padding: 4px 6px;
          background: grey;
          border-radius: 17px;
        }
      }
    }
  }
}

.flyoutBlock.pdfContainer {
  overflow: auto;
  margin: calc(var(--flyout-padding) / 2) 0;
}

.linkContainer {
  font: inherit;
  text-decoration: none;
  padding: 0.75rem;
  margin: 0.5rem 0.2rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: white;
  color: #10365a;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 1px 6px #00000080;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 45%;
}
