@use 'src/styles/flyout';

.container {
  margin-top: 30px;
  border-top: 1px solid var(--primary-color);
  padding-top: 30px;

  .day {
    font-size: 16px;
    line-height: 14px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 20px;
  }
}

.noShifts {
  border-radius: 8px;
  background: #e9edf1;
  margin-top: 25px;
  text-align: center;
  padding: 20px 0;
  color: var(--primary-color);
  font-weight: 700;
  line-height: 14px;
  font-size: 14px;
}
