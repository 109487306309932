@use 'src/styles/flyout';

.root {
  padding: 0 var(--flyout-padding) var(--flyout-padding);
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;

  &,
  * {
    box-sizing: border-box;
  }
}

.openQueueStatus {
  font-style: italic;
  margin-left: 20px;
  color: green;
  font-weight: 400;
}
.closedQueueStatus {
  font-style: italic;
  margin-left: 20px;
  color: red;
  font-weight: 400;
}

.queueContainer {
  display: flex;
  justify-content: left;
  margin-top: 0.5rem;
  margin-left: 2rem;
  position: relative;

  & > div {
    display: flex;
    align-items: center;

    & > span {
      margin-right: 0.5rem;
    }

    .number {
      font-size: 0.9em;
    }

    .icon {
      margin-top: -0.3rem;
      color: #8599ab;
    }
    .stats {
      left: -9999px;
      margin-right: -10rem;
      position: absolute;
      background-color: white;
      top: 2rem;
      padding: 1rem;
      z-index: 9999;
      box-shadow: -1px 1px 2px 1px rgb(0 0 0 / 20%);
      border-radius: 4px;

      max-width: 90%;

      & > div {
        width: 100%;
      }

      .time {
        color: #8599ab;
      }
    }
  }

  div:hover {
    .stats {
      left: 0;
    }
  }

  .name {
    color: var(--light-info-color);
    font-weight: 400;
  }
}
