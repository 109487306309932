.root {
  position: absolute;
  top: 15px;
  right: 15px;

  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  text-align: right;
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;

  color: var(--primary-color, #103559);
  background: var(--highlight-yellow-color);

  &.arrived {
    color: var(--white-color);
    background: var(--dark-green-color);
  }

  &.noShow {
    color: var(--white-color);
    background: var(--error-color);
  }
}
