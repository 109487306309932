.lookup {
  :global(.promo-code-select__control) {
    min-height: 35px;
    max-height: 150px;
    border-radius: 18px;
  }

  :global(.promo-code-select__value-container) {
    max-height: 130px;
    overflow-y: scroll;
  }

  :global(.promo-code-select__placeholder) {
    color: var(--primary-color);
  }

  :global(.select__dropdown-indicator) {
    color: var(--primary-color);
  }

  :global(.promo-code-select__indicator) {
    color: var(--primary-color);
  }

  :global(.promo-code-select__single-value) {
    margin-top: 3px;
  }

  :global(.promo-code-select__menu) {
    z-index: 100000;
  }

  :global(.promo-code-select__option):has(.promoCodeSeparator) {
    border-top: 1px solid #C4C4C4CC;
  }

  .placeholder {
    display: inline-flex;
    align-items: flex-end;
    left: 6px;
    top: 2px;
    position: relative;
    color: #10365a;
    opacity: 0.5;
  }
}


.promoCodeOption {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 0;
  color: var(--primary-color);

  &.invalid {
    color: #616161;
  }

  > .error {
    font-size: 12px;
    color: var(--secondary-color);
  }

  &.hidden {
    display: none;
  }
}

.promoCodeSeparator {
  padding: 8px 0;

  > button {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    color: var(--secondary-color);
    text-decoration: underline;
    cursor: pointer;
  }
}
