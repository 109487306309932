@use 'src/styles/flyout';

.container {
  display: grid;
  grid-template-rows: max-content max-content 1fr max-content;
  height: calc(100vh - 4px);
  position: absolute;
  top: 0;
  width: 100%;
}

.grid {
  overflow: scroll;
  font-size: var(--font-size-small);
}

.gridLine {
  display: grid;
  grid-template-columns: max-content repeat(7, 1fr);
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
  &:hover {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
  }
}

.rowHeader {
  font-weight: 500;
  margin-top: 2px;
  height: 16px;
}

.role {
}

.totals {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid var(--primary-color);
}

.totalSum {
  font-weight: 500;
}

.dayHeader {
  margin-top: 10px;
  text-align: center;
  color: var(--staff-color-text);

  .date {
    font-weight: 500;
  }
}

.locationBlock {
  margin-top: 10px;
  &:not(:first-child) {
    margin-top: 40px;
  }
}

.txSxSeparator {
  height: 10px;
}

.statsBlock {
  display: grid;
  grid-template-columns: 35px 45px 20px 20px 20px;
  padding: 0 10px;

  > .role,
  > .count,
  > .underStaffedCount,
  > .overStaffedCount {
    margin-top: 2px;
  }
}

.columnsGroup {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.columns {
  &:nth-child(odd) {
    background-color: var(--white-color);
  }
}

.employeesBlock {
  display: grid;
  grid-template-columns: 1fr 20px 20px;
  padding: 0 10px;

  .noNumbers {
    grid-column: 1 / -1;
  }

  .statNumber {
    margin-top: 2px;
  }

  .count {
    color: var(--staff-color-text);
    text-align: right;
  }
}

.totalsBlock {
  display: grid;
  grid-template-columns: 25px 25px 25px;
  padding: 0 10px;
}

.employeesLine {
  margin-bottom: 2px;
}

.totalsRowHeader {
  display: grid;
  grid-template-columns: 70px 70px;
  padding: 0 10px;

  > div {
    margin-top: 0;
  }
}

.employeeBadgeContainer {
  position: relative;
  display: inline-block;
}

.employeeBadge {
  position: relative;
  border-radius: 6px;
  padding: 3px 3px 0 2px;
  color: var(--white-color);
  font-weight: 500;
  margin-right: 2px;
  min-width: 22px;
  text-align: center;
  margin-bottom: 1px;
  cursor: default;
}

.fullName {
  display: block;
}

.clickable {
  cursor: pointer;
}

.fullNameContent {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.fullNameText {
  flex-grow: 1;
  text-align: left;
  padding-left: 5px;
}

.initialsContent {
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
}

.initialsText {
  display: flex;
  align-items: center;
}

.time {
  font-size: 10px;
  font-weight: 400;
}

.locationName {
  grid-column: 1 / -1;
  margin-bottom: 5px;
  margin-left: 10px;
  font-size: var(--font-size-medium);
}

.doctor,
.surgeryDoctor {
  color: var(--staff-color-doctor);
  stroke: var(--staff-color-doctor);
  background-color: var(--staff-bgcolor-doctor);
}

.trainingDoctor {
  color: var(--staff-color-training-doctor);
  stroke: var(--staff-color-training-doctor);
  background-color: var(--staff-bgcolor-training-doctor);
}

.nurse,
.surgeryNurse {
  color: var(--staff-color-nurse);
  stroke: var(--staff-color-nurse);
  background-color: var(--staff-bgcolor-nurse);
}

.assistant,
.surgeryAssistant {
  color: var(--staff-color-assistant);
  stroke: var(--staff-color-assistant);
  background-color: var(--staff-bgcolor-assistant);
}

.manager {
  color: var(--staff-color-manager);
  stroke: var(--staff-color-manager);
  background-color: var(--staff-bgcolor-manager);
}

.careCoordinator {
  color: var(--staff-color-carecoordinator);
  stroke: var(--staff-color-carecoordinator);
  background-color: var(--staff-bgcolor-carecoordinator);
}

.overStaffed {
  background-color: var(--staff-color-overstaffed);
}

.overStaffedIgnored {
  box-shadow:
    1px 1px 0 0 var(--staff-color-overstaffed),
    -1px -1px 0 0 var(--staff-color-overstaffed),
    1px -1px 0 0 var(--staff-color-overstaffed),
    -1px 1px 0 0 var(--staff-color-overstaffed);
  border: 1px solid var(--staff-color-overstaffed);
  padding: 2px 2px 0 1px;
  margin-bottom: 0;
}

.underStaffed {
  background-color: var(--staff-color-understaffed);
}

.underStaffedIgnored {
  background-color: var(--staff-color-understaffed);
  opacity: 0.3;
}

.noRoleColor {
  background-color: var(--staff-color-no-role);
}

.staffingOk {
  opacity: 0.3;
}

.count {
  color: var(--staff-color-text);
  text-align: right;
}

.overStaffedCount {
  color: var(--staff-color-overstaffed);
  text-align: right;
}

.underStaffedCount {
  color: var(--staff-color-understaffed);
  text-align: right;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingIcon {
  color: var(--light-info-color);
}

.error {
  position: fixed;
  width: 80%;
  margin-left: 10%;
  top: 100px;
}

.shiftTimeIcon {
  margin-top: -4px;
  padding: 0 2px 0 4px;
}

.initialsShiftTimeIcon {
  width: 22px;
  padding: 2px 2px 0 4px;
}

.noNameShiftTimeIcon {
  width: 22px;
  padding: 2px 2px 0 0;
}

.filtersNotReflected {
  background-color: var(--warning-color);
  text-align: center;
  padding: 12px;
  font-weight: 500;
}

.startPage {
  background: var(--white-color);
  text-align: center;
  align-content: center;
  font-size: 32px;
  font-weight: 500;
}
