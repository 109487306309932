.lookup {
  :global(.creditreason-select__control) {
    min-height: 35px;
    max-height: 150px;
    border-radius: 18px;
  }

  :global(.creditreason-select__value-container) {
    max-height: 130px;
    overflow-y: scroll;
  }

  :global(.creditreason-select__placeholder) {
    color: var(--primary-color);
  }

  :global(.select__dropdown-indicator) {
    color: var(--primary-color);
  }

  :global(.creditreason-select__indicator) {
    color: var(--primary-color);
  }

  :global(.creditreason-select__single-value) {
    margin-top: 3px;
  }

  :global(.creditreason-select__menu) {
    z-index: 100000;
  }

  .placeholder {
    display: inline-flex;
    align-items: flex-end;
    left: 6px;
    top: 2px;
    position: relative;
    color: #10365a;
    opacity: 0.5;
  }
}
