.container {
  margin: 20px;
  padding: 20px;
  background-color: var(--error-color);
  color: var(--white-color);
  border-radius: 5px;
  max-height: 100px;
}

.header {
  font-weight: var(--font-weight-bold);
}

.message {
  margin-top: 8px;
}
